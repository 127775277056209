import React from "react";
import axios from "axios";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IBillboard } from "../../interfaces/Billboard";
import { debounce } from "@material-ui/core";
import { BillboardSearchFields } from "../../constants/BillboardsSearchFields";
import { BillboardOptionLabel } from "./BillboardOptionLabel";

export interface IProps {
  onChange: (billboard: IBillboard) => void;
  startDate: Date;
  endDate: Date;
}

const getBillboards = debounce(
  (
    props: IProps,
    setBillboards: (billboards: IBillboard[]) => void,
    searchValue: string
  ) => {
    axios
      .get("/api/billboards/available", {
        params: {
          searchValue,
          searchFields: BillboardSearchFields,
          attributes: [...BillboardSearchFields, "id"],
          startDate: props.startDate.getTime(),
          endDate: props.endDate.getTime(),
          onlyAvailable: 1,
          is_public: 1,
          is_not_standing: 0,
          size: 50,
        },
      })
      .then((response) => {
        setBillboards(response.data.billboards);
      })
      .catch((error) => console.log(error));
  },
  300
);

export const BillboardDropdown: React.FC<IProps> = (props) => {
  const [billboards, setBillboards] = React.useState<IBillboard[]>([]);
  const { onChange } = props;

  const onInputChange = React.useCallback(
    (event) => {
      getBillboards(props, setBillboards, event.currentTarget.value);
    },
    [props]
  );

  const onBillboardSelected = React.useCallback(
    (event, billboard: string | IBillboard) => {
      onChange(billboard ? (billboard as IBillboard) : null);
    },
    [onChange]
  );

  React.useEffect(() => {
    getBillboards(props, setBillboards, "");
  }, [props]);

  return (
    <Box>
      <Autocomplete
        id="billboard-dropdown"
        options={billboards}
        // Workaround - Autocomplete currently doesn't support filtering on multiple values when option is an object
        getOptionLabel={(option) =>
          `${option.search_nr} ${option.city} ${option.district} ${option.address_details} ${option.description}`
        }
        renderOption={(option: IBillboard) => (
          <BillboardOptionLabel
            search_nr={option.search_nr}
            city={option.city}
            district={option.district}
            address_details={option.address_details}
            description={option.description}
          />
        )}
        onChange={onBillboardSelected}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Wybierz billboard"
            variant="outlined"
            onChange={onInputChange}
          />
        )}
      />
    </Box>
  );
};
