import React, { useCallback } from "react";

import { getEndDate } from "../utils";

import { DateRangePicker } from "@mui/lab";
import { TextField, Checkbox, FormControlLabel, Box } from "@mui/material";
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import { makeStyles } from "@mui/styles";
import { useBillboardOccupancy } from "../hooks/useBillboardOccupancy";

// Max date supported by mysql - 9999-12-31 23:59:59
const MAX_DATE_TIMESTAMP = 253402297199000;

interface IProps extends ISelectedDates {
  onChange: (selectedDates: ISelectedDates) => void;
  onValidation?: (entries: string[], billboardId?: number) => void;
  billboardId?: number;
}

export interface ISelectedDates {
  startDate: Date;
  endDate: Date;
}

const useStyles = makeStyles({
  root: {},
  dateSelectorEl: {
    marginRight: "10px",
  },
});

const getDefaultDatepickerEndDate = (endDate: Date) =>
  endDate.getTime() === MAX_DATE_TIMESTAMP ? getEndDate() : endDate;

export const BillboardDatesSelector: React.FC<IProps> = (props) => {
  const { startDate, endDate, billboardId, onChange, onValidation } = props;
  const endDateRef = React.useRef(getDefaultDatepickerEndDate(endDate));

  const classes = useStyles();
  const [billboardOccupancy, isLoading] = useBillboardOccupancy(billboardId);

  const onDateChange = useCallback(
    (newDate: RangeInput<Date>) => {
      if (newDate[0] !== null && newDate[1] !== null) {
        endDateRef.current = newDate[1] as Date;
        onChange({
          startDate: newDate[0] as Date,
          endDate: newDate[1] as Date,
        });
      }
    },
    [onChange]
  );

  const onIndefinitelyDateChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onChange({
          startDate,
          endDate: new Date(MAX_DATE_TIMESTAMP),
        });
      } else {
        onChange({
          startDate,
          endDate: endDateRef.current,
        });
      }
    },
    [startDate, onChange]
  );

  const onError = useCallback(
    (errors: string[]) => {
      onValidation(errors, billboardId);
    },
    [billboardId, onValidation]
  );

  return (
    <React.Fragment>
      <DateRangePicker
        startText="Start kampanii"
        endText="Koniec kampanii"
        value={[startDate, endDate]}
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        onChange={onDateChange}
        disablePast={true}
        disableCloseOnSelect={false}
        loading={isLoading}
        onError={onError}
        shouldDisableDate={(date: Date) =>
          billboardOccupancy.some(
            ({ date_from, date_to }) => date_from <= date && date_to >= date
          )
        }
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Box sx={{ mx: 1 }} />
            <TextField
              {...endProps}
              inputProps={{
                ...endProps.inputProps,
                disabled: endDate.getTime() === MAX_DATE_TIMESTAMP,
                value:
                  endDate.getTime() === MAX_DATE_TIMESTAMP
                    ? "-"
                    : endProps.inputProps.value,
              }}
            />
          </React.Fragment>
        )}
      />
      <Box sx={{ mx: 1 }} />
      <FormControlLabel
        className={classes.dateSelectorEl}
        control={
          <Checkbox
            checked={endDate.getTime() === MAX_DATE_TIMESTAMP}
            onChange={onIndefinitelyDateChecked}
            name="IndefinitelyDate"
          />
        }
        label="Bezterminowo"
      />
    </React.Fragment>
  );
};

BillboardDatesSelector.defaultProps = {
  onValidation: () => {},
};
