import { GridCellParams, GridRenderEditCellParams } from "@mui/x-data-grid";

type fieldValueType = string | number | boolean | object | Date;

export function getFieldValue<T extends fieldValueType>(
  field: string,
  params: GridCellParams | GridRenderEditCellParams
): T {
  return params.getValue(params.id, field) as T;
}
