import React, { useCallback } from "react";
import axios from "axios";
import { RouteComponentProps, useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Save } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import { AdminResource } from "../components/AdminResource";
import { formatDate } from "../utils";
import { SuccessNotification } from "../components/SuccessNotification";
import { AppContext } from "../store/store";
import { appActions } from "../store/action";
import { IContractDocument } from "../interfaces/ContractDocument";
import { ContractBillboardsTable } from "../components/Table/ContractBillboardsTable";
import { AddBillboardForm } from "../components/Forms/AddBillboardForm";
import { IBillboard } from "../interfaces/Billboard";
import { ISelectedDates } from "../components/BillboardDatesSelector";

const SIGN_NOTIFICATION_ID = "OffersItem|successSign";
const SAVE_NOTIFICATION_ID = "OffersItem|successSave";

interface IRouteInfo {
  id: string;
}

const useStyles = makeStyles(() => ({
  signButton: {
    marginTop: "8px",
  },
}));

const getOffer = (id: number): Promise<IContractDocument> =>
  new Promise((resolve) => {
    axios
      .get("/api/contracts/details", {
        params: {
          id,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => console.log(error));
  });

const editOffer = (
  id: number,
  contractName: string,
  billboards: IBillboard[]
): Promise<IContractDocument> =>
  new Promise((resolve) => {
    axios
      .post("/api/contracts/edit", {
        id,
        billboards,
        contract_name: contractName,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => console.log(error));
  });

const signContract = (id: number) =>
  axios.post("/api/contracts/sign", {
    id,
  });

export const OfferItem: React.FC<RouteComponentProps<IRouteInfo>> = () => {
  const [offer, setOffer] = React.useState<IContractDocument>(null);
  const [saveChangesDisabled, setSaveChangesDisabled] = React.useState(true);
  const id = Number(useParams<IRouteInfo>().id);
  const [, dispatch] = React.useContext(AppContext);

  const classes = useStyles();

  React.useEffect(() => {
    getOffer(id).then((contractDetails: IContractDocument) => {
      setOffer({
        ...contractDetails,
        billboards: contractDetails.billboards.map((billboard) => ({
          ...billboard,
          date_from: new Date(billboard.date_from),
          date_to: new Date(billboard.date_to),
        })),
      });
    });
  }, [id]);

  const onSignContract = React.useCallback(() => {
    signContract(id).then(() => {
      dispatch({
        type: appActions.NOTIFICATION_OPEN,
        payload: {
          notificationId: SIGN_NOTIFICATION_ID,
        },
      });

      setTimeout(() => {
        window.location.replace("/campaigns#tab=1");
      }, 1000);
    });
  }, [id, dispatch]);

  const onSaveContract = React.useCallback(() => {
    editOffer(
      id,
      offer.contract_name,
      offer.billboards.map((billboard) => ({
        id: billboard.id,
        date_from: billboard.date_from.getTime(),
        date_to: billboard.date_to.getTime(),
      }))
    ).then((contractDetails: IContractDocument) => {
      setOffer({
        ...contractDetails,
        billboards: contractDetails.billboards.map((billboard) => ({
          ...billboard,
          date_from: new Date(billboard.date_from),
          date_to: new Date(billboard.date_to),
        })),
      });
      dispatch({
        type: appActions.NOTIFICATION_OPEN,
        payload: {
          notificationId: SAVE_NOTIFICATION_ID,
        },
      });
      setSaveChangesDisabled(true);
    });
  }, [id, offer, setOffer, dispatch]);

  const onContractNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOffer({
        ...offer,
        contract_name: event.target.value,
      });
      setSaveChangesDisabled(false);
    },
    [offer, setOffer]
  );

  const onBillboardAdded = React.useCallback(
    (billboardData) => {
      setOffer({
        ...offer,
        billboards: offer.billboards.concat([billboardData]),
      });
      setSaveChangesDisabled(false);
    },
    [offer, setOffer]
  );

  const onDatesChange = useCallback(
    (selectedDates: ISelectedDates, rowId: number) => {
      const billboards = [...offer.billboards];

      billboards[rowId] = {
        ...billboards[rowId],
        date_from: selectedDates.startDate,
        date_to: selectedDates.endDate,
      };

      setOffer({
        ...offer,
        billboards,
      });
      setSaveChangesDisabled(false);
    },
    [offer, setOffer]
  );

  const onBillboardRemoved = React.useCallback(
    (rowId: number) => {
      const billboards = [...offer.billboards];

      billboards.splice(rowId, 1);

      setOffer({
        ...offer,
        billboards,
      });
      setSaveChangesDisabled(false);
    },
    [offer, setOffer]
  );

  return offer === null ? null : (
    <div>
      <Container maxWidth="lg">
        <Grid container spacing={0}>
          <Grid item xs={12} container>
            <Grid item xs={12} md={9}>
              <Typography variant="overline">Kampania</Typography>
              <br />
              <Typography variant="h4">
                {offer.client.name} - {offer.contract_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <AdminResource>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSignContract}
                  className={classes.signButton}
                >
                  Stwórz kontrakt
                </Button>
              </AdminResource>
              <br />
              <br />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Save />}
                onClick={onSaveContract}
                disabled={saveChangesDisabled}
              >
                Zapisz zmiany
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} container md={9}>
            <AdminResource>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Nazwa"
                variant="outlined"
                value={offer.contract_name}
                onChange={onContractNameChange}
              />
            </AdminResource>
            Data utworzenia: {formatDate(offer.created_at)}
            <br />
            <br />
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant="h6">Lista tablic</Typography>
            </Grid>
            <Grid item xs={12}>
              <ContractBillboardsTable
                billboards={offer.billboards}
                contractId={id}
                editable={true}
                onBillboardDelete={onBillboardRemoved}
                onDatesChange={onDatesChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <AddBillboardForm onBillboardAdded={onBillboardAdded} />
      <SuccessNotification
        id={SIGN_NOTIFICATION_ID}
        label="Oferta została zaakceptowana."
      />
      <SuccessNotification
        id={SAVE_NOTIFICATION_ID}
        label="Zmiany w ofercie zostały pomyślnie zapisane."
      />
    </div>
  );
};
