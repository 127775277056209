import axios from "axios";
import { IContact } from "../../interfaces/Contact";

export const addContact = (contactData: IContact): Promise<IContact[]> => {
  return new Promise((resolve) => {
    axios
      .post("/api/contacts/add", {
        data: contactData,
      })
      .then((response) => {
        resolve(response.data.contacts);
      })
      .catch((error) => {
        console.error(error);
        resolve([]);
      });
  });
};

export const editContact = (contactData: IContact): Promise<IContact[]> => {
  return new Promise((resolve) => {
    axios
      .post("/api/contacts/edit", {
        id: contactData.id,
        data: contactData,
      })
      .then((response) => {
        resolve(response.data.contacts);
      })
      .catch((error) => {
        console.error(error);
        resolve([]);
      });
  });
};

export const getContacts = (query: string = ""): Promise<IContact[]> => {
  return new Promise((resolve) => {
    axios
      .get(`/api/contacts${query}`)
      .then((response) => {
        resolve(response.data.contacts);
      })
      .catch((error) => {
        console.error(error);
        resolve([]);
      });
  });
};
