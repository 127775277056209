import { GridRowId, GridRowParams } from "@mui/x-data-grid";
import React, { useCallback, useContext, useEffect } from "react";
import { DataContext } from "../../dataStore/DataContext";
import { IClient } from "../../interfaces/Client";
import { ClientColumns } from "./ColumnDefinitions/ClientColumns";
import { Table } from "./Table";

interface IProps {
  clients: IClient[];
  selectionModel: GridRowId[];
  onRowClick?: (client: IClient) => void;
}

export const ClientsTable: React.FC<IProps> = (props) => {
  const { clients, selectionModel, onRowClick } = props;
  const [state] = useContext(DataContext);

  useEffect(() => {
    const clientId = selectionModel[0];
    if (clientId) {
      const client = clients.find((client: IClient) => clientId === client.id);

      if (client) {
        onRowClick(client);
      }
    }
  }, [selectionModel, onRowClick, clients]);

  const onRowSelected = useCallback(
    (params: GridRowParams) => onRowClick(params.row as IClient),
    [onRowClick]
  );

  return (
    <Table
      rows={clients}
      columns={ClientColumns}
      checkboxSelection={false}
      onRowClick={onRowSelected}
      loading={state.isLoading}
    />
  );
};

ClientsTable.defaultProps = {
  onRowClick: () => {},
};
