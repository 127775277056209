import React from "react";

import {
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
} from "@mui/x-data-grid";
import Delete from "@mui/icons-material/Delete";

import { IBillboard } from "../../interfaces/Billboard";
import { DeleteButton } from "../DeleteButton";
import { BillboardColumns } from "./ColumnDefinitions/BillboardColumns";
import { Table } from "./Table";
import { ISelectedDates } from "../BillboardDatesSelector";
import { DateEditTemplate } from "./EditTemplates/DateEditTemplate";
import { getFieldValue } from "./utils/utils";

interface IProps {
  billboards: IBillboard[];
  contractId: number;
  editable?: boolean;
  onDatesChange?: (selectedDates: ISelectedDates, rowId: number) => void;
  onBillboardDelete?: (rowId: number) => void;
}

const renderDateEditCell = (
  props: IProps,
  params: GridRenderEditCellParams
) => {
  const { id, value, api, field } = params;

  const onChange = (newDate: Date) => {
    api.setEditCellValue({ id, field, value: newDate });
    api.commitCellChange({ id, field, value: newDate });
    props.onDatesChange(
      {
        startDate: getFieldValue<Date>(BillboardColumns.dateFrom.field, params),
        endDate: getFieldValue<Date>(BillboardColumns.dateTo.field, params),
      },
      getFieldValue<number>("_rowId", params)
    );
  };

  return (
    <DateEditTemplate
      value={value as Date}
      onChange={onChange}
      billboardId={getFieldValue<number>(BillboardColumns.id.field, params)}
      contractIdsToExclude={[props.contractId]}
    />
  );
};

const getDeleteCell = (props: IProps) => ({
  field: "",
  headerName: "",
  width: 40,
  disableColumnMenu: true,
  sortable: false,
  renderCell: (params: GridCellParams) => (
    <DeleteButton
      confirmationContentText="Czy na pewno chcesz usunąć wskazany billboard z listy?"
      onConfirm={() =>
        props.onBillboardDelete(getFieldValue<number>("_rowId", params))
      }
    >
      <Delete />
    </DeleteButton>
  ),
});

const getColumns = (props: IProps): GridColDef[] => {
  const tableColumns: GridColDef[] = [
    BillboardColumns.id,
    BillboardColumns.searchNr,
    BillboardColumns.addressDetails,
    BillboardColumns.city,
    {
      ...BillboardColumns.dateFrom,
      editable: props.editable,
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderDateEditCell(props, params),
    },
    {
      ...BillboardColumns.dateTo,
      editable: props.editable,
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderDateEditCell(props, params),
    },
  ];

  if (props.editable) {
    tableColumns.push(getDeleteCell(props));
  }

  return tableColumns;
};

const mapBillboards = (billboards: IBillboard[]) =>
  billboards.map((billboard, key) => ({
    ...billboard,
    _rowId: key,
  }));

export const ContractBillboardsTable: React.FC<IProps> = (props) => {
  return (
    <Table
      height={"calc(70vh - 260px)"}
      rows={mapBillboards(props.billboards)}
      columns={getColumns(props)}
      checkboxSelection={false}
      pageSize={10}
      getRowId={(row) => {
        return row._rowId;
      }}
    />
  );
};

ContractBillboardsTable.defaultProps = {
  editable: false,
  onBillboardDelete: () => {},
};
