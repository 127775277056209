import React from "react";
import axios from "axios";
import { RouteComponentProps, useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import TextField from '@material-ui/core/TextField';
import { IContact } from "../interfaces/Contact";

interface IRouteInfo {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteInfo> {}

const getContact = (id: string, setContact: (contact: IContact) => void) => {
  axios
    .get(`/api/contacts`, {
      params: {
        filter: "id",
        query: id,
      },
    })
    .then((response) => {
      console.log(response.data.contacts);
      setContact(response.data.contacts[0]);
    })
    .catch((error) => console.log(error));
};

export const User: React.FC<IProps> = () => {
  const [contact, setContact] = React.useState<IContact>(null);
  const { id } = useParams<IRouteInfo>();
  console.log('kasia');
  console.log(contact);

  React.useEffect(() => {
    getContact(id, setContact);
  }, [id]);


  return contact === null ? null : (
    <Container maxWidth="lg">
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Typography variant="h4">Lista:</Typography>

          <Button variant="contained" color="secondary" 
                startIcon={<Add />}>
          Zapisz
          </Button>
          
        </Grid>
        <div style={{ height: 600, width: "100%" }}>
          <TextField id="outlined-basic" label="Nazwisko" variant="outlined" value={contact.last_name} />
          <TextField id="outlined-basic" label="Imię" variant="outlined" value={contact.first_name} />
          <TextField id="outlined-basic" label="Imię" variant="outlined" value={contact.street} />
          <TextField id="outlined-basic" label="Imię" variant="outlined" value={contact.zip_code} />
          <TextField id="outlined-basic" label="Imię" variant="outlined" value={contact.city} />
          <TextField id="outlined-basic" label="Imię" variant="outlined" value={contact.industry_type} />
          <TextField id="outlined-basic" label="Imię" variant="outlined" value={contact.tel} />
          <TextField id="outlined-basic" label="Imię" variant="outlined" value={contact.tel_mobile} />
        </div>
      </Grid>
    </Container>
  );
}
