export enum appActions {
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  CLEAR_BASKET,
  UPDATE_BASKET,
  SET_TOKEN,
  NOTIFICATION_OPEN,
  NOTIFICATION_CLOSE,
  MODAL_OPEN,
  MODAL_CLOSE,
}
