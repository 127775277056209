import React, { useCallback, useContext, useEffect } from "react";
import { GridCellParams, GridRowId, GridRowParams } from "@mui/x-data-grid";
import { Table } from "./Table";
import { DataContext } from "../../dataStore/DataContext";
import { DataActions } from "../../dataStore/DataActions";
import { getContacts } from "../../services/rest/contacts";
import { IContact } from "../../interfaces/Contact";

interface IProps {
  onRowClick?: (contact: IContact) => void;
  selectionModel: GridRowId[];
  isLoading?: boolean;
}

const columns = [
  { field: "id", headerName: "ID", width: 60 },
  {
    field: "last_name",
    headerName: "Nazwa",
    width: 230,
    renderCell: (params: GridCellParams) => (
      <span style={{ lineHeight: "20px" }}>
        {params.getValue(params.id, "last_name")}{" "}
        {params.getValue(params.id, "first_name")}
        <div style={{ lineHeight: "20px" }}>
          <small>{params.getValue(params.id, "industry_info")}</small>
        </div>
      </span>
    ),
  },
  { field: "tel", headerName: "Telefon", width: 180 },
  { field: "tel_mobile", headerName: "Komórkowy", width: 150 },
  { field: "email", headerName: "e-mail", width: 230 },
  {
    field: "address",
    headerName: "Adres",
    width: 330,
    renderCell: (params: GridCellParams) => (
      <span>
        {params.getValue(params.id, "city")}{" "}
        {params.getValue(params.id, "zip_code")}{" "}
        {params.getValue(params.id, "street")}
      </span>
    ),
  },
  {
    field: "stored_contractors_serialized",
    headerName: "Kontrahenci",
    width: 130,
  },
];

export const ContactsTable: React.FC<IProps> = (props) => {
  const [state, dispatch] = useContext(DataContext);
  const { selectionModel, onRowClick } = props;

  useEffect(() => {
    if (state.contacts.length === 0) {
      dispatch({
        type: DataActions.ENABLE_LOADING,
      });

      getContacts().then((contacts) => {
        dispatch({
          type: DataActions.SET_CONTACTS,
          payload: {
            contacts,
          },
        });
        dispatch({
          type: DataActions.DISABLE_LOADING,
        });
      });
    }
  }, [dispatch, state.contacts.length]);

  useEffect(() => {
    const contactId = selectionModel[0];
    if (contactId) {
      const contact = state.contacts.find(
        (contact: IContact) => contactId === contact.id
      );

      if (contact) {
        onRowClick(contact);
      }
    }
  }, [selectionModel, onRowClick, state.contacts]);

  const onRowSelected = useCallback(
    (params: GridRowParams) => onRowClick(params.row as IContact),
    [onRowClick]
  );

  return (
    <Table
      rows={state.contacts}
      columns={columns}
      checkboxSelection={false}
      loading={state.isLoading}
      selectionModel={props.selectionModel}
      onRowClick={onRowSelected}
    />
  );
};
