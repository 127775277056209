import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { useState } from "react";

export const debounce = (func: any, wait: any) => {
  let timeout: any;

  return function executedFunction(...args: any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getEndDate = () => {
  const endDate = new Date();
  // Last day of next month = (current month + 2) - 1 day
  endDate.setMonth(endDate.getMonth() + 2, 0);

  return endDate;
};

export const formatDate = (props: string) => {
  let date = new Date(props);
  let dateNew = format(date, "do LLLL yyyy, HH:mm", { locale: pl });
  return String(dateNew);
};

export const UrlHash = {
  get: (key?: string) => {
    const hash = window.location.hash.substr(1);

    const result = hash
      .split("&")
      .reduce(function (res: { [key: string]: string }, item) {
        var parts = item.split("=");
        res[parts[0]] = parts[1];
        return res;
      }, {});

    return key !== undefined ? result[key] : result;
  },
  set: (hash: string) => {
    window.location.hash = hash;
  },
  remove: () => (window.location.hash = ""),
};

export const useMergedState: <T>(
  initialState: T
) => [state: T, setMergedState: (newState: Partial<T>) => void] = (
  initialState
) => {
  const [state, setState] = useState(initialState);

  const setMergedState = (newState: any) =>
    setState((prevState) => Object.assign({}, prevState, newState));

  return [state, setMergedState];
};
