import React, { useContext } from "react";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { IClient } from "../interfaces/Client";
import Box from "@material-ui/core/Box";
import { AdminResource } from "../components/AdminResource";
import { PreviewClient } from "../components/PreviewClient";
import { ClientsTable } from "../components/Table/ClientsTable";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { getClients } from "../services/rest/clients";
import { DataContext } from "../dataStore/DataContext";
import { DataActions } from "../dataStore/DataActions";
import { UrlHash } from "../utils";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const getUrlClientId = () =>
  UrlHash.get("client") !== undefined ? [Number(UrlHash.get("client"))] : [];

export function Clients() {
  const [clients, setClients] = React.useState([]);
  const [, dispatch] = useContext(DataContext);

  const urlClientId = getUrlClientId();

  React.useEffect(() => {
    dispatch({ type: DataActions.ENABLE_LOADING });
    getClients().then((clients) => {
      setClients(clients);
      dispatch({ type: DataActions.DISABLE_LOADING });
    });
  }, [dispatch]);

  const [drawerState, setState] = React.useState(false);
  const [select, setSelection] = React.useState<IClient>(null);

  const toggleDrawer = () => {
    if (drawerState) {
      UrlHash.remove();
    }

    setState(!drawerState);
  };

  const openDrawer = (client: IClient) => {
    setState(true);
    setSelection(client);
    UrlHash.set(`client=${client.id}`);
  };

  //TODO: Check disableClickEventBubbling props
  return (
    <AdminResource>
      <Container maxWidth={false}>
        <Grid container spacing={0}>
          <Grid item xs={12} container>
            <Grid item xs={9}>
              <Box mr={4} my={1}>
                <Typography variant="h4">Kontrahenci</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignContent="flex-start"
            >
              <Box my={1}>
                <Link to={`/clients/add/`} aria-label="Dodaj kontrahenta">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                  >
                    Dodaj kontrahenta
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Box my={1}></Box>
          </Grid>
          <ClientsTable
            clients={clients}
            onRowClick={openDrawer}
            selectionModel={urlClientId}
          />
          {select === null ? null : (
            <PreviewClient
              item={select}
              drawerState={drawerState}
              onDrawerClose={toggleDrawer}
            />
          )}
        </Grid>
      </Container>
    </AdminResource>
  );
}
