import React from "react";

import {
  DataGrid,
  DataGridProps,
  GridSelectionModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

interface IProps extends DataGridProps {
  onSelectionChanged?: (newSelection: GridSelectionModel) => void;
  height?: string;
}

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

export const Table: React.FC<IProps> = (props) => {
  const { onSelectionChanged, height, ...rest } = props;

  return (
    <div style={{ height: height, width: "100%" }}>
      <DataGrid
        checkboxSelection
        disableColumnMenu
        components={{
          Toolbar: CustomToolbar,
        }}
        onSelectionModelChange={(newSelection) => {
          onSelectionChanged(newSelection);
        }}
        rowHeight={52}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // ALways hide id column
              id: false,
            },
          },
        }}
        {...rest}
      />
    </div>
  );
};

Table.defaultProps = {
  height: "calc(100vh - 260px)",
  onSelectionChanged: () => {},
};
