import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { UrlHash } from "../utils";
import { ContractDocumentsTable } from "../components/Table/ContractDocumentsTable";
import { OfferDocumentsTable } from "../components/Table/OfferDocumentsTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
}

const getActiveTab = () =>
  UrlHash.get("tab") !== undefined ? Number(UrlHash.get("tab")) : 0;

function TabPanel(props: TabPanelProps) {
  const { children, index, ...other } = props;
  const value = getActiveTab();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function Campaigns() {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    UrlHash.set(`tab=${newValue}`);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={0}>
        <Grid item xs={12} container>
          <Grid item xs={12} md={9}>
            <Box mr={4} my={2}>
              <Typography variant="h4">Kampanie</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            container
            justifyContent="flex-end"
            alignContent="flex-start"
          >
            {/*
            <Box my={2}>
              ADMIN ONLY
              <Button variant="contained" color="primary" startIcon={<Add />}>
                Stwórz ofertę
              </Button>
             </Box> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={getActiveTab()}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="W trakcie weryfikacji (Oferty)" {...a11yProps(0)} />
            <Tab label="Zatwierdzone (Kontrakty)" {...a11yProps(1)} />
          </Tabs>
          <TabPanel index={0}>
            <OfferDocumentsTable />
          </TabPanel>
          <TabPanel index={1}>
            <ContractDocumentsTable />
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
}
