import React, { useCallback } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";

import { AdminResource } from "../components/AdminResource";
import { ContactsTable } from "../components/Table/ContactsTable";
import { IContact } from "../interfaces/Contact";
import { PreviewContact } from "../components/PreviewContact";
import { Modal } from "../components/Modal";
import { AddContactForm } from "../components/Forms/AddContactForm";
import { appActions } from "../store/action";
import { AppContext } from "../store/store";
import { DataContext } from "../dataStore/DataContext";
import { DataActions } from "../dataStore/DataActions";
import { addContact, editContact } from "../services/rest/contacts";
import { UrlHash } from "../utils";

const NEW_CONTACT_MODAL = "NewContactModal";
const EDIT_CONTACT_MODAL = "EditContactModal";

const getUrlContactId = () =>
  UrlHash.get("contact") !== undefined ? [Number(UrlHash.get("contact"))] : [];

export function Contacts() {
  const [, dispatch] = React.useContext(AppContext);
  const [, dispatchData] = React.useContext(DataContext);
  const [selectedContact, setSelectedContact] = React.useState<IContact>(null);
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

  const urlContactId = getUrlContactId();

  const togglePreview = useCallback(() => {
    if (isPreviewOpen) {
      UrlHash.remove();
    }
    setIsPreviewOpen(!isPreviewOpen);
  }, [isPreviewOpen, setIsPreviewOpen]);

  const onRowClick = useCallback(
    (contact: IContact) => {
      setIsPreviewOpen(true);
      setSelectedContact(contact);
      UrlHash.set(`contact=${contact.id}`);
    },
    [setIsPreviewOpen, setSelectedContact]
  );

  const openAddContactModal = useCallback(() => {
    dispatch({
      type: appActions.MODAL_OPEN,
      payload: {
        modalId: NEW_CONTACT_MODAL,
      },
    });
  }, [dispatch]);

  const openEditContactModal = useCallback(() => {
    dispatch({
      type: appActions.MODAL_OPEN,
      payload: {
        modalId: EDIT_CONTACT_MODAL,
      },
    });
  }, [dispatch]);

  const onContactAdd = useCallback(
    (contactData: IContact) => {
      dispatch({
        type: appActions.MODAL_CLOSE,
        payload: {
          modalId: NEW_CONTACT_MODAL,
        },
      });
      dispatchData({
        type: DataActions.ENABLE_LOADING,
      });

      addContact(contactData).then((contacts) => {
        dispatchData({
          type: DataActions.SET_CONTACTS,
          payload: {
            contacts,
          },
        });
        dispatchData({
          type: DataActions.DISABLE_LOADING,
        });
      });
    },
    [dispatch, dispatchData]
  );

  const onContactEdit = useCallback(
    (contactData: IContact) => {
      dispatch({
        type: appActions.MODAL_CLOSE,
        payload: {
          modalId: EDIT_CONTACT_MODAL,
        },
      });
      dispatchData({
        type: DataActions.ENABLE_LOADING,
      });

      editContact(contactData).then((contacts) => {
        dispatchData({
          type: DataActions.SET_CONTACTS,
          payload: {
            contacts,
          },
        });
        dispatchData({
          type: DataActions.DISABLE_LOADING,
        });
      });
    },
    [dispatch, dispatchData]
  );

  return (
    <AdminResource>
      <Container maxWidth={false}>
        <Grid container spacing={0}>
          <Grid item xs={12} container>
            <Grid item xs={9}>
              <Box mr={4} my={1}>
                <Typography variant="h4">Kontakty</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignContent="flex-start"
            >
              <Box my={1}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={openAddContactModal}
                >
                  Dodaj kontakt
                </Button>

                <Modal modalId={NEW_CONTACT_MODAL} title="Dodaj kontakt">
                  <AddContactForm
                    onContactSubmit={onContactAdd}
                    defaultValues={null}
                  />
                </Modal>
                <Modal modalId={EDIT_CONTACT_MODAL} title="Edytuj kontakt">
                  <AddContactForm
                    onContactSubmit={onContactEdit}
                    defaultValues={selectedContact}
                    saveLabel="Zapisz"
                  />
                </Modal>
              </Box>
            </Grid>
          </Grid>
          <ContactsTable
            onRowClick={onRowClick}
            selectionModel={urlContactId}
          />
          {selectedContact === null ? null : (
            <PreviewContact
              contact={selectedContact}
              drawerState={isPreviewOpen}
              onDrawerClose={togglePreview}
              onContactEdit={openEditContactModal}
            />
          )}
        </Grid>
      </Container>
    </AdminResource>
  );
}
