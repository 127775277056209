import React from "react";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import { AdminResource } from "../components/AdminResource";
import { AddClientForm } from "../components/Forms/AddClientForm";
import { IClient } from "../interfaces/Client";
import axios from "axios";
import { SuccessNotification } from "../components/SuccessNotification";
import { AppContext } from "../store/store";
import { appActions } from "../store/action";
import { useParams } from "react-router-dom";

const NOTIFICATION_ID = "Basket|Success";

interface IRouteInfo {
  id: string;
}

const getClient = (id: string, setClient: (client: IClient) => void) => {
  axios
    .get(`/api/clients`, {
      params: {
        id,
      },
    })
    .then((response) => {
      console.log(response.data.clients);
      setClient(response.data.clients[0]);
    })
    .catch((error) => console.log(error));
};

const editClient = (id: string, clientData: IClient) =>
  axios.post("/api/clients/edit", {
    id,
    data: clientData,
  });

export function ClientsEdit() {
  const [, dispatch] = React.useContext(AppContext);
  const [client, setClient] = React.useState<IClient>(null);
  const { id } = useParams<IRouteInfo>();

  React.useEffect(() => {
    getClient(id, setClient);
  }, [id]);

  const onClientUpdated = React.useCallback(
    (clientData: IClient) => {
      editClient(id, clientData).then(() => {
        dispatch({
          type: appActions.NOTIFICATION_OPEN,
          payload: {
            notificationId: NOTIFICATION_ID,
          },
        });
      });
    },
    [id, dispatch]
  );

  return (
    client && (
      <AdminResource>
        <React.Fragment>
          <Container maxWidth="lg">
            <Grid container spacing={0}>
              <Grid item xs={12} container>
                <AddClientForm
                  onClientSubmit={onClientUpdated}
                  defaultValues={client}
                  saveLabel="Zapisz"
                />
              </Grid>
            </Grid>
          </Container>
          <SuccessNotification
            id={NOTIFICATION_ID}
            label="Dane kontrahenta zostały zmienione."
          />
        </React.Fragment>
      </AdminResource>
    )
  );
}
