import axios from "axios";
import React from "react";

export interface IAdminResourceProps {
  children: React.ReactElement;
}

export const getAdminResourcePermission = (
  setAdminResourcePermission: (status: boolean) => void
) => {
  axios
    .get("/api/resources/allowed")
    .then((response) => setAdminResourcePermission(response.data));
};

export const AdminResource = (props: IAdminResourceProps) => {
  const [adminResourcePermission, setAdminResourcePermission] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getAdminResourcePermission(setAdminResourcePermission);
  }, []);

  return adminResourcePermission ? props.children : null;
};
