import { appActions } from "./action";
import { IAppContextAction, AppContextState } from "./store";

export const appReducer = (
  state: AppContextState,
  action: IAppContextAction
) => {
  const { type, payload } = action;
  let basket = [...state.basket];

  switch (type) {
    case appActions.ADD_TO_BASKET:
      return {
        ...state,
        basket: basket.concat(payload.shopItems),
      };
    case appActions.REMOVE_FROM_BASKET:
      payload.shopItems.forEach((item) => {
        basket.splice(item.billboardIndex, 1);
      });

      return {
        ...state,
        basket,
      };

    case appActions.UPDATE_BASKET:
      payload.shopItems.forEach((item) => {
        const { billboardIndex, ...rest } = item;
        basket[billboardIndex] = {
          ...rest,
        };
      });

      return {
        ...state,
        basket,
      };

    case appActions.CLEAR_BASKET:
      return {
        ...state,
        basket: [],
      };
    case appActions.SET_TOKEN:
      return {
        ...state,
        token: payload.token,
      };

    case appActions.NOTIFICATION_OPEN:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [payload.notificationId]: true,
        },
      };

    case appActions.NOTIFICATION_CLOSE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [payload.notificationId]: false,
        },
      };
    case appActions.MODAL_OPEN:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modalId]: true,
        },
      };

    case appActions.MODAL_CLOSE:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modalId]: false,
        },
      };

    default:
      return state;
  }
};
