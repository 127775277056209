import React from "react";

import { Link } from "react-router-dom";
import { Chip, makeStyles } from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import Tooltip from "@mui/material/Tooltip";
import {
  regions,
  roadSetup,
  zoneNumber,
} from "../../../constants/BillboardConstants";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";

const StatusCell = (params: GridCellParams) => {
  let statusClass;
  let iconSet;
  const useStyles = makeStyles(() => ({
    free: {
      background: "#02ba02",
      width: "24px",
      paddingLeft: "8px",
    },
    taken: {
      background: "Red",
      width: "24px",
      paddingLeft: "8px",
    },
  }));

  const classes = useStyles();

  switch (params.getValue(params.id, "status")) {
    case 0:
      statusClass = classes.free;
      iconSet = (
        <Tooltip title="Wolny">
          <EventAvailableIcon />
        </Tooltip>
      );
      break;
    case 2:
      statusClass = classes.taken;
      iconSet = (
        <Tooltip title="Zajęty">
          <EventBusyIcon />
        </Tooltip>
      );
  }
  return (
    <Chip
      size="small"
      className={statusClass}
      label=" "
      icon={iconSet}
      color="primary"
    />
  );
};

export const BillboardColumns: { [id: string]: GridColDef } = {
  id: {
    field: "id",
    type: "number",
    hide: true,
  },
  searchNr: {
    field: "search_nr",
    headerName: "#",
    width: 70,
    renderCell: (params: GridCellParams) => (
      <Link to={`/billboards/${params.getValue(params.id, "id")}`}>
        <b>#{params.getValue(params.id, "search_nr")}</b>
      </Link>
    ),
  },
  addressDetails: { field: "address_details", headerName: "Adres", width: 300 },
  size: {
    field: "size",
    headerName: "Wymiary",
    width: 90,
    renderCell: (params: GridCellParams) => {
      const width = params.getValue(params.id, "width");
      const height = params.getValue(params.id, "height");

      switch (true) {
        case width === 504 && height === 238:
          return (
            <span style={{ color: "black" }}>
              {width} x {height}
            </span>
          );
        case width === 600 && height === 300:
          return (
            <span style={{ color: "blue" }}>
              {width} x {height}
            </span>
          );

        default:
          return (
            <span style={{ color: "red" }}>
              {width} x {height}
            </span>
          );
      }
    },
  },
  status: {
    field: "status",
    headerName: "Status",
    width: 60,
    renderCell: StatusCell,
  },
  city: {
    field: "city",
    headerName: "Miejscowość",
    width: 120,
  },
  district: { field: "district", headerName: "Dystrykt", width: 120 },
  roadFrom: {
    field: "road_from",
    headerName: "Kierunek najazdu",
    width: 110,
    renderCell: (params: GridCellParams) => (
      <span>
        {params.getValue(params.id, "road_from")} {"-> "}
        {params.getValue(params.id, "road_to")}
      </span>
    ),
  },
  roadNumber: {
    field: "road_number",
    headerName: "Trasa",
    width: 115,
  },
  hasLight: {
    type: "boolean",
    field: "has_light",
    headerName: "Light",
    width: 70,
  },
  onInlet: {
    type: "boolean",
    field: "on_inlet",
    headerName: "Wlot",
    width: 70,
  },
  isNearToMall: {
    type: "boolean",
    field: "is_near_to_mall",
    headerName: "C.H.",
    width: 70,
  },
  zoneNumber: {
    field: "zone_number",
    headerName: "Strefa",
    width: 250,
    renderCell: (params: GridCellParams) => (
      <span>
        {zoneNumber[params.getValue(params.id, "zone_number") as number]}
      </span>
    ),
  },
  regionId: {
    field: "region_id",
    headerName: "Rejon",
    width: 150,
    renderCell: (params: GridCellParams) => (
      <span>{regions[params.getValue(params.id, "region_id") as number]}</span>
    ),
  },
  roadSetup: {
    field: "road_setup",
    headerName: "Ustawienie",
    width: 150,
    renderCell: (params: GridCellParams) => (
      <span>
        {roadSetup[params.getValue(params.id, "road_setup") as number]}
      </span>
    ),
  },
  dateFrom: {
    field: "date_from",
    headerName: "Start",
    width: 200,
    editable: true,
    type: "date",
  },
  dateTo: {
    field: "date_to",
    headerName: "Koniec",
    width: 200,
    editable: true,
    type: "date",
  },
};
