import React, { useCallback } from "react";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { AppContext } from "../store/store";
import { appActions } from "../store/action";

interface IProps {
  modalId: string;
  title?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const Modal: React.FC<IProps> = (props) => {
  const { modalId } = props;
  const [state, dispatch] = React.useContext(AppContext);

  const onModalClose = useCallback(() => {
    dispatch({
      type: appActions.MODAL_CLOSE,
      payload: {
        modalId: modalId,
      },
    });
  }, [modalId, dispatch]);

  return (
    <>
      <Dialog
        onClose={onModalClose}
        aria-labelledby="customized-dialog-title"
        open={!!state.modals[modalId]}
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={onModalClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers>{props.children}</DialogContent>
      </Dialog>
    </>
  );
};
