import React from "react";

import { GridCellParams } from "@mui/x-data-grid";

export const ClientColumns = [
  { field: "id", headerName: "ID", width: 60, type: "number" },
  {
    field: "name",
    headerName: "Nazwa",
    width: 300,
    renderCell: (params: GridCellParams) => (
      <span style={{ lineHeight: "20px" }}>
        {params.getValue(params.id, "name")} (
        {params.getValue(params.id, "full_name")})
        <div style={{ lineHeight: "20px" }}>
          <small>{params.getValue(params.id, "industry_info")}</small>
        </div>
      </span>
    ),
  },
  { field: "tel", headerName: "Telefon", width: 180 },
  { field: "tel_mobile", headerName: "Komórkowy", width: 150 },
  { field: "email", headerName: "e-mail", width: 230 },
  {
    field: "address",
    headerName: "Adres",
    width: 330,
    renderCell: (params: GridCellParams) => (
      <span>
        {params.getValue(params.id, "city")}{" "}
        {params.getValue(params.id, "zip_code")}{" "}
        {params.getValue(params.id, "street")}
      </span>
    ),
  },
  {
    field: "stored_contractors_serialized",
    headerName: "Klienci",
    width: 130,
  },
];
