import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import Paper from "@material-ui/core/Paper";

import { IContact } from "../interfaces/Contact";

interface IProps {
  onDrawerClose?: () => void;
  onContactEdit?: () => void;
  drawerState: boolean;
  contact: IContact;
}

export const PreviewContact: React.FC<IProps> = (props) => {
  const { contact } = props;

  return contact === null ? null : (
    <>
      <Drawer
        anchor="right"
        open={props.drawerState}
        onClose={props.onDrawerClose}
        variant="persistent"
      >
        <Box m={4} width={500}>
          <Grid item container justifyContent="space-between">
            <Box my={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Edit />}
                onClick={props.onContactEdit}
              >
                Edytuj kontakt
              </Button>
            </Box>
            <IconButton color="primary" onClick={props.onDrawerClose}>
              <Close />
            </IconButton>
          </Grid>
          <Typography variant="subtitle2">Kontakt </Typography>
          <Typography variant="h4">
            {contact.first_name} {contact.last_name}
          </Typography>

          <Box my={2}>
            <Typography variant="body1">{contact.industry_type}</Typography>
          </Box>

          <Paper>
            <Box my={2} p={1}>
              <Typography variant="overline">Adres</Typography> <br />
              <Typography variant="body1">
                {contact.street}
                <br /> {contact.zip_code} {contact.city}
              </Typography>
            </Box>
          </Paper>

          <Paper>
            <Box my={2} p={1}>
              <Typography variant="overline">Dane kontaktowe</Typography> <br />
              <Box my={2}>
                <Typography variant="body1">{contact.tel}</Typography>
              </Box>
              <Box my={2}>
                <Typography variant="body1">{contact.tel_alt}</Typography>
              </Box>
              <Box my={2}>
                <Typography variant="body1">{contact.tel_mobile}</Typography>
              </Box>
              <Box my={2}>
                <Typography variant="body1">{contact.email}</Typography>
              </Box>
              <Box my={2}>
                <Typography variant="body1">{contact.email_alt}</Typography>
              </Box>
              {contact.nip ? (
                <Box my={2}>
                  <Typography variant="body1">NIP {contact.nip}</Typography>
                </Box>
              ) : (
                ""
              )}
              <Box my={2}>
                <Typography variant="body1">{contact.www}</Typography>
              </Box>
              {contact.bank_account_nb ? (
                <Box my={2}>
                  <Typography variant="body1">
                    Bank {contact.bank_account_nb}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              {contact.skype ? (
                <Box my={2}>
                  <Typography variant="body1">Skype {contact.skype}</Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Paper>
          <Paper>
            <Box my={2} p={1}>
              <Typography variant="overline">Opis</Typography> <br />
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {contact.description}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Drawer>
    </>
  );
};
