import { IContact } from "../interfaces/Contact";

export interface IDataContextAction {
  type: DataActions;
  payload?: {
    contacts?: IContact[];
  };
}

export enum DataActions {
  ENABLE_LOADING,
  DISABLE_LOADING,
  SET_CONTACTS,
}
