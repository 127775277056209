import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MenuIcon from "@material-ui/icons/Menu";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { grey } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import { Menu } from "../components/Menu";
import { logout } from "../services/auth.services";
import { QuickSearch } from "../components/QuickSearch";
import { AppContext } from "../store/store";
import { appActions } from "../store/action";
import { AdminResource } from "../components/AdminResource";
import { Hidden, SwipeableDrawer } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  grow: {
    display: "flex",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50ch",
    },
  },
  avatar: {
    color: theme.palette.getContrastText(blueGrey[400]),
    backgroundColor: blueGrey[400],
  },
  menuLink: {
    textDecoration: "none",
  },
  drawer: {
    width: "100vw",
  },
  drawerPaper: {
    width: "100vw",
    paddingTop: "20px",
  },
}));

export function TopBar() {
  const classes = useStyles();
  const [state, dispatch] = useContext(AppContext);

  const onLogout = React.useCallback(() => {
    logout().then(() => {
      dispatch({
        type: appActions.SET_TOKEN,
        payload: {
          token: null,
        },
      });
    });
  }, [dispatch]);

  const [stateMenu, setStateMenu] = React.useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setStateMenu(open);
    };

  const linksAdmin = (
    <AdminResource>
      <div>
        {/*<Link to="/">Home</Link>
      <Link to="/contacts" className={classes.menuLink}><Button>Kontakty (A)</Button></Link>
      <Link to="/contactors" className={classes.menuLink}><Button>Kontrahenci (A)(-)</Button></Link>
      <Tooltip title="Ulubione">
      <IconButton style={{ color: deepOrange[700] }} aria-label="Ulubione">
        <StarIcon />
      </IconButton>
      </Tooltip>*/}
        {/* 
        orders_ordersummary
      */}
        <Link
          to="/clients"
          className={classes.menuLink}
          style={{ marginLeft: "15px", marginRight: "15px" }}
        >
          <Button>Kontrahenci</Button>
        </Link>
        <Link
          to="/contacts"
          className={classes.menuLink}
          style={{ marginLeft: "15px", marginRight: "15px" }}
        >
          <Button>Kontakty</Button>
        </Link>
        {/* <Link
            to="/dashboard"
            className={classes.menuLink}
            style={{ marginLeft: "15px", marginRight: "15px" }}
          >
            <Button>Dashboard</Button>
          </Link> */}
        {/* <Link
          to="/contracts"
          className={classes.menuLink}
          style={{ marginLeft: "15px", marginRight: "15px" }}
        >
          <Button>Umowy (kontrakty)</Button>
        </Link> */}
      </div>
    </AdminResource>
  );

  const linksListLeft = (
    <div style={{ display: "flex" }}>
      <Link
        to="/campaigns"
        className={classes.menuLink}
        style={{ marginLeft: "15px", marginRight: "15px" }}
        onClick={toggleDrawer(false)}
      >
        <Button>
          Kampanie
          {/* <Badge badgeContent=" " variant="dot" color="error">
              Kampanie
            </Badge> */}
        </Button>
      </Link>
    </div>
  );

  const linksListRight = (
    <div style={{ display: "flex", alignItems: "center" }}>
      {" "}
      <QuickSearch />
      <Menu
        id="simple-menu2"
        label={
          <Avatar className={classes.avatar}>
            <PersonIcon />
          </Avatar>
        }
      >
        {/* <MenuItem onClick={() => { }}>Profil (-)</MenuItem> */}
        <MenuItem onClick={onLogout}>Wyloguj</MenuItem>
      </Menu>
    </div>
  );

  const basket = (
    <Link to="/basket" aria-label="Koszyk">
      <Tooltip title="Koszyk">
        <IconButton style={{ color: blueGrey[700] }}>
          <Badge badgeContent={state.basket.length} color="error">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    </Link>
  );

  return state.token ? (
    <AppBar position="fixed" style={{ background: grey[200] }}>
      <Toolbar style={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "Center" }}>
          <Hidden mdUp implementation="css">
            <IconButton
              edge="start"
              onClick={toggleDrawer(true)}
              style={{ color: blueGrey[700] }}
            >
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              anchor="left"
              open={stateMenu}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              className={classes.drawer}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {linksAdmin}
              {linksListLeft}
              {linksListRight}
            </SwipeableDrawer>
          </Hidden>
          <Typography variant="h6">
            <Link
              to="/billboards"
              style={{ color: "black", display: "flex", alignItems: "center" }}
              className={classes.menuLink}
            >
              <img
                src="/logo.png"
                alt="Parejo logo"
                style={{ height: "30px", marginRight: "10px" }}
              />
              <Button>Billboardy</Button>
            </Link>
          </Typography>

          <Hidden smDown implementation="css">
            <div style={{ display: "flex", alignItems: "Center" }}>
              {linksAdmin}
              {linksListLeft}
            </div>
          </Hidden>
        </div>
        <div className={classes.grow}>
          {basket}
          <Hidden smDown implementation="css">
            {linksListRight}
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  ) : null;
}
