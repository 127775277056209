import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { IClient } from "../../interfaces/Client";
import TextField from "@material-ui/core/TextField";

interface IProps {
  onClientSubmit: (client: IClient) => void;
  defaultValues?: IClient;
  saveLabel?: string;
}

const useStyles = makeStyles(() => ({
  form: {
    marginTop: "20px",
  },
  errorMessage: {
    fontSize: "12px",
    color: "#f00",
  },
}));

export const AddClientForm: React.FC<IProps> = (props) => {
  const { onClientSubmit } = props;
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: props.defaultValues,
    mode: "onChange",
  });

  const classes = useStyles();

  const onSubmit = React.useCallback(
    (clientData: IClient) => {
      console.log(clientData);
      onClientSubmit(clientData);
    },
    [onClientSubmit]
  );

  return (
    <Container maxWidth="lg" className={classes.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignContent="center" alignItems="center">
          <Grid item xs={12} sm={3}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
                maxLength: 100,
              }}
              render={({ field }) => (
                <TextField
                  label="Skrócona nazwa"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <Controller
              name="full_name"
              control={control}
              rules={{
                maxLength: 250,
              }}
              render={({ field }) => (
                <TextField label="Pełna nazwa" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="industry_type"
              control={control}
              render={({ field }) => (
                <TextField label="branża" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="nip"
              control={control}
              render={({ field }) => (
                <TextField label="NIP" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="regon"
              control={control}
              render={({ field }) => (
                <TextField label="REGON" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="krs"
              control={control}
              render={({ field }) => (
                <TextField label="KRS" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="bank_account_nb"
              control={control}
              render={({ field }) => (
                <TextField label="Rachunek" variant="outlined" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="tel"
              control={control}
              render={({ field }) => (
                <TextField label="tel" variant="outlined" {...field} />
              )}
            />

            <Controller
              name="tel_alt"
              control={control}
              render={({ field }) => (
                <TextField label="tel" variant="outlined" {...field} />
              )}
            />

            <Controller
              name="tel_mobile"
              control={control}
              render={({ field }) => (
                <TextField label="GSM" variant="outlined" {...field} />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value: /^.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                  message: "Nieprawidłowy adres email",
                },
              }}
              render={({ field }) => (
                <TextField
                  label="email firmowy"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <div className={classes.errorMessage}>
              {errors.email && errors.email.message}
            </div>
            <Controller
              name="email_alt"
              control={control}
              rules={{
                pattern: {
                  value: /^.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                  message: "Nieprawidłowy adres email",
                },
              }}
              render={({ field }) => (
                <TextField
                  label="email księgowość"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <div className={classes.errorMessage}>
              {errors.email_alt && errors.email_alt.message}
            </div>
            <Controller
              name="www"
              control={control}
              render={({ field }) => (
                <TextField label="www" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="skype"
              control={control}
              render={({ field }) => (
                <TextField label="skype" variant="outlined" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="street"
              control={control}
              render={({ field }) => (
                <TextField label="ulica" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="zip_code"
              control={control}
              render={({ field }) => (
                <TextField label="kod pocztowy" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField label="miejscowość" variant="outlined" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="mail_street"
              control={control}
              render={({ field }) => (
                <TextField label="ulica koresp" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="mail_zip_code"
              control={control}
              render={({ field }) => (
                <TextField
                  label="kod pocztowy koresp"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <Controller
              name="mail_city"
              control={control}
              render={({ field }) => (
                <TextField
                  label="miejscowość koresp"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  label="opis"
                  variant="outlined"
                  multiline
                  rows={8}
                  {...field}
                  style={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!getValues("name") || Object.keys(errors).length > 0}
            >
              {props.saveLabel}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

AddClientForm.defaultProps = {
  defaultValues: {},
  saveLabel: "Dodaj kontrahenta",
};
