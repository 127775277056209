import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { login } from "../services/auth.services";
import { RouteComponentProps } from "react-router-dom";
import { AppContext } from "../store/store";
import { appActions } from "../store/action";

interface IProps extends RouteComponentProps<{}> {}

interface IFormData {
  username: string;
  password: string;
}

const useStyles = makeStyles({
  form: {
    textAlign: "center",
    display: "block",
  },
  submitButton: {
    display: "block",
    margin: "20px auto",
  },
  errorMessage: {
    fontSize: "14px",
    color: "#f00",
    margin: "10px",
  },
});

export const Login: React.FC<IProps> = (props) => {
  const [, dispatch] = React.useContext(AppContext);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleLogin = ({ username, password }: IFormData) => {
    setMessage("");
    setLoading(true);

    login(username, password).then(
      (token) => {
        dispatch({
          type: appActions.SET_TOKEN,
          payload: {
            token: token,
          },
        });
        window.location.replace(
          props.location.state && (props.location.state as any).from.path
            ? (props.location.state as any).from.path
            : "/home"
        );
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item md={4} xs={1}></Grid>
        <Grid item md={4} xs={10}>
          <div style={{ textAlign: "center" }}>
            <img src="/logo.png" style={{ height: "40px" }} alt="Parejo logo" />
            <br />
            <br />
          </div>
          <Paper elevation={3}>
            <Box p={6}>
              Zaloguj się do systemu rezerwacji
              <br />
              <br />
              <div className={classes.errorMessage}>{message}</div>
              <br />
              <div className="card card-container">
                <form
                  onSubmit={handleSubmit(handleLogin)}
                  className={classes.form}
                >
                  <Controller
                    name="username"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Pole jest wymagane",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Login"
                        className="form-control"
                        fullWidth
                      />
                    )}
                  />
                  <div className={classes.errorMessage}>
                    {errors.username && errors.username.message}
                  </div>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Pole jest wymagane",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="password"
                        label="Hasło"
                        className="form-control"
                        fullWidth
                      />
                    )}
                  />
                  <div className={classes.errorMessage}>
                    {errors.password && errors.password.message}
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.submitButton}
                    disabled={Object.keys(errors).length > 0 || loading}
                  >
                    Zaloguj się
                  </Button>
                </form>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
