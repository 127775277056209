import TextField, { TextFieldProps } from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: 4,
  },
}));

export const FormTextField: React.FC<TextFieldProps> = (props) => {
  const { wrapper } = useStyles();
  return (
    <div className={wrapper}>
      <TextField {...props} />
    </div>
  );
};
