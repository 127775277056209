import { IContact } from "../interfaces/Contact";
import { DataActions, IDataContextAction } from "./DataActions";

export type DataContextState = {
  contacts?: IContact[];
  isLoading?: boolean;
};

export const initialState: DataContextState = {
  contacts: [],
  isLoading: false,
};

export const DataReducer = (
  state: DataContextState,
  action: IDataContextAction
) => {
  const { type, payload } = action;

  switch (type) {
    case DataActions.ENABLE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case DataActions.DISABLE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case DataActions.SET_CONTACTS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
