import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";

import { IContractDocument } from "../../interfaces/ContractDocument";
import { OfferDocumentsColumns } from "./ColumnDefinitions/OfferDocumentsColumns";
import { Table } from "./Table";

const getOffers = (): Promise<IContractDocument[]> => {
  return new Promise((resolve) => {
    axios
      .get(`/api/contracts`, {
        params: {
          size: 10000,
          is_signed: 0,
        },
      })
      .then((response) => {
        resolve(response.data.contractDocuments);
      })
      .catch((error) => {
        resolve([]);
        console.log(error);
      });
  });
};

export function OfferDocumentsTable() {
  const [offerDocuments, setOfferDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getOffers().then((unsignedContractDocuments) => {
      setOfferDocuments(unsignedContractDocuments);
      setIsLoading(false);
    });
  }, []);

  return (
    <Table
      rows={offerDocuments}
      columns={OfferDocumentsColumns}
      pageSize={20}
      rowsPerPageOptions={[10, 20, 50]}
      pagination
      checkboxSelection
      isLoading={isLoading}
    />
  );
}
