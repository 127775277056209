import axios from "axios";

const API_URL = "/api/auth/";

export const login = (username: string, password: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          resolve(response.data.token);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => {
  return axios.post(API_URL + "signout");
};
