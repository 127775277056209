import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { IContractDocument } from "../../interfaces/ContractDocument";
import { formatDate } from "../../utils";
import { IClient } from "../../interfaces/Client";
import { GridCellParams } from "@mui/x-data-grid";
import { Table } from "./Table";

const getContractDocuments = (
  setContractDocuments: (contractDocuments: IContractDocument[]) => void,
  query: string
) => {
  axios
    .get(`/api/contracts`, {
      params: {
        size: 10000,
        is_signed: 1,
      },
    })
    .then((response) => {
      console.log(response.data.contractDocuments);
      setContractDocuments(response.data.contractDocuments);
    })
    .catch((error) => console.log(error));
};

const columns = [
  {
    field: "number",
    headerName: "Nr",
    width: 130,
    renderCell: (params: GridCellParams) => (
      <Link to={`/contracts/${params.getValue(params.id, "id")}`}>
        <b>{params.getValue(params.id, "number")}</b>
      </Link>
    ),
  },
  { field: "contract_name", headerName: "Nazwa", width: 230 },
  {
    field: "client_id",
    headerName: "Klient",
    width: 130,
    renderCell: (params: GridCellParams) => (
      <span>{(params.getValue(params.id, "client") as IClient).name}</span>
    ),
  },
  {
    field: "created_at",
    headerName: "data",
    width: 230,
    renderCell: (params: GridCellParams) => (
      <span>
        {formatDate(String(params.getValue(params.id, "created_at")))}
      </span>
    ),
  },
];

export function ContractDocumentsTable() {
  const [contractDocuments, setContractDocuments] = React.useState([]);

  React.useEffect(() => {
    getContractDocuments(setContractDocuments, "");
  }, []);

  //TODO: Check disableClickEventBubbling props
  return (
    <Table
      rows={contractDocuments}
      columns={columns}
      pageSize={20}
      rowsPerPageOptions={[10, 20, 50]}
      pagination
      checkboxSelection
      // disableClickEventBubbling={true}
    />
  );
}
