import React from "react";

import Box from "@mui/material/Box";
import DesktopDatePicker from "@mui/lab/DatePicker";

import { useBillboardOccupancy } from "../../../hooks/useBillboardOccupancy";
import { makeStyles } from "@mui/styles";
import Input from "@mui/material/Input";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";

interface IProps {
  value: Date;
  onChange: (newValue: Date) => void;
  billboardId?: number;
  contractIdsToExclude?: number[];
}

// Max date supported by mysql - 9999-12-31 23:59:59
// const MAX_DATE_TIMESTAMP = 253402297199000;

const useStyles = makeStyles({
  dateInput: {
    fontSize: "14px !important",
  },
});

export const DateEditTemplate: React.FC<IProps> = (props) => {
  const [billboardOccupancy, isLoading] = useBillboardOccupancy(
    props.billboardId,
    props.contractIdsToExclude
  );
  const classes = useStyles();

  return (
    <React.Fragment>
      <DesktopDatePicker
        label="dateEditTemplate"
        value={props.value}
        onChange={props.onChange}
        disablePast={true}
        loading={isLoading}
        mask="__.__.____"
        inputFormat="dd.MM.yyyy"
        shouldDisableDate={(date: Date) =>
          billboardOccupancy.some(
            ({ date_from, date_to }) => date_from <= date && date_to >= date
          )
        }
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Input
              ref={inputRef}
              {...InputProps}
              value={inputProps.value}
              className={classes.dateInput}
            />
          </Box>
        )}
      />
      {/* <div>
        <FormControlLabel
          // className={classes.dateSelectorEl}
          control={
            <Checkbox
              checked={props.value.getTime() === MAX_DATE_TIMESTAMP}
              // onChange={onIndefinitelyDateChecked}
              name="IndefinitelyDate"
            />
          }
          label="Bezterminowo"
        />
      </div> */}
    </React.Fragment>
  );
};
