import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { IBillboard } from "../../interfaces/Billboard";
import { getEndDate } from "../../utils";
import { BillboardDatesSelector } from "../BillboardDatesSelector";
import { BillboardDropdown } from "../Dropdowns/BillboardDropdown";
import Typography from "@material-ui/core/Typography";

interface IProps {
  onBillboardAdded: (billboard: IBillboard) => void;
}

interface IBillboardData {
  billboard: IBillboard;
  selectedDates: {
    startDate: Date;
    endDate: Date;
  };
}

const useStyles = makeStyles(() => ({
  form: {
    marginTop: "20px",
  },
}));

export const AddBillboardForm: React.FC<IProps> = (props) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      billboard: null,
      selectedDates: {
        startDate: new Date(),
        endDate: getEndDate(),
      },
    },
  });

  const { onBillboardAdded } = props;
  const classes = useStyles();
  const watchSelectedDates = watch("selectedDates");
  const watchBillboard = watch("billboard");

  const onSubmit = React.useCallback(
    (billboardData: IBillboardData) => {
      onBillboardAdded({
        ...billboardData.billboard,
        date_from: billboardData.selectedDates.startDate,
        date_to: billboardData.selectedDates.endDate,
      });
    },
    [onBillboardAdded]
  );

  return (
    <Container maxWidth="lg" className={classes.form}>
      <Typography variant="h6">Dodaj tablice</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          alignContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={3}>
            <Controller
              name="billboard"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <BillboardDropdown
                  {...field}
                  startDate={watchSelectedDates.startDate}
                  endDate={watchSelectedDates.endDate}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} container>
            <Controller
              name="selectedDates"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <BillboardDatesSelector
                  {...field}
                  billboardId={watchBillboard?.id}
                  startDate={field.value.startDate}
                  endDate={field.value.endDate}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              type="submit"
              variant="contained"
              disabled={
                !getValues("billboard") || Object.keys(errors).length > 0
              }
            >
              Dodaj Billboard
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
