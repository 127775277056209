import axios from "axios";
import { IBillboard } from "../../interfaces/Billboard";

export const getBillboards = (startDate: Date, endDate: Date) =>
  new Promise<IBillboard[]>((resolve) => {
    axios
      .get("/api/billboards/available", {
        params: {
          size: 2000,
          is_public: 1,
          is_not_standing: 0,
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
        },
      })
      .then((response) => {
        resolve(response.data.billboards);
      })
      .catch((error) => {
        console.log(error);
        resolve([]);
      });
  });

export const getBillboard = (id: string) =>
  new Promise<IBillboard>((resolve) => {
    axios
      .get(`/api/billboards`, {
        params: {
          id,
        },
      })
      .then((response) => {
        resolve(response.data.billboards[0]);
      })
      .catch((error) => {
        console.log(error);
        resolve(null);
      });
  });

export const addBillboard = (data: IBillboard): Promise<string> => {
  return new Promise((resolve) => {
    axios
      .post("/api/billboards/add", {
        data,
      })
      .then((response) => {
        resolve(response.data.id);
      })
      .catch((error) => {
        console.error(error);
        resolve(null);
      });
  });
};

export const editBillboard = ({ id, ...rest }: IBillboard): Promise<string> => {
  return new Promise((resolve) => {
    axios
      .post("/api/billboards/edit", {
        id,
        data: rest,
      })
      .then((response) => {
        resolve(response.data.id);
      })
      .catch((error) => {
        console.error(error);
        resolve(null);
      });
  });
};
