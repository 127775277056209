import React from "react";
import axios from "axios";
import { RouteComponentProps, useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Save } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import { IContractDocument } from "../interfaces/ContractDocument";
import { Box } from "@material-ui/core";
import { AdminResource } from "../components/AdminResource";
import { formatDate } from "../utils";
import { ContractBillboardsTable } from "../components/Table/ContractBillboardsTable";

interface IRouteInfo {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteInfo> {}

const getContractDocuments = (
  id: number,
  setContractDocuments: (contractDocuments: IContractDocument) => void
) => {
  axios
    .get(`/api/contracts/details`, {
      params: {
        id,
        is_signed: 1,
      },
    })
    .then((response) => {
      setContractDocuments(response.data);
    })
    .catch((error) => console.log(error));
};

export const ContractDocumentsItem: React.FC<IProps> = () => {
  const [contractDocuments, setContractDocuments] =
    React.useState<IContractDocument>(null);
  const { id } = useParams<IRouteInfo>();

  React.useEffect(() => {
    getContractDocuments(Number(id), setContractDocuments);
  }, [id]);

  return contractDocuments === null ? null : (
    <Container maxWidth="lg">
      <Grid container spacing={0}>
        <Grid item xs={12} container>
          <Grid item xs={12} md={9}>
            <Typography
              variant="overline"
              display="block"
              style={{ fontWeight: "bold" }}
            >
              Kontrakt{" "}
            </Typography>
          </Grid>
          <AdminResource>
            <Grid item xs={12} md={3}>
              <Button variant="contained" color="primary" startIcon={<Save />}>
                Zapisz
              </Button>
            </Grid>
          </AdminResource>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h5" display="block">
            {contractDocuments.number} - {contractDocuments.client.name}{" "}
            {contractDocuments.contract_name}
          </Typography>
          <Box my={4}>
            <AdminResource>
              <div>
                <TextField
                  id="outlined-basic"
                  label="Klient"
                  disabled
                  variant="outlined"
                  value={contractDocuments.client.name}
                />
                <br />
                <br />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Nazwa"
                  variant="outlined"
                  value={contractDocuments.contract_name}
                />
                <br />
                <br />
                <TextField
                  id="outlined-basic"
                  label="Numer"
                  variant="outlined"
                  value={contractDocuments.number}
                />
                <br />
              </div>
            </AdminResource>
            <div>
              Data utworzenia: {formatDate(contractDocuments.created_at)}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <br />
          <Box>
            <Typography variant="h5">Lista tablic</Typography>
          </Box>
          <br />
          <Box>
            <ContractBillboardsTable
              billboards={contractDocuments.billboards}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
