import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useForm, Controller } from "react-hook-form";

import { IContact } from "../../interfaces/Contact";
import { ContactCategoryDropdown } from "../Dropdowns/ContactCategoryDropdown";
import { FormTextField } from "./Fields/TextField";

interface IProps {
  onContactSubmit: (client: IContact) => void;
  defaultValues?: IContact;
  saveLabel?: string;
}

const useStyles = makeStyles(() => ({
  form: {
    marginTop: "20px",
  },
  errorMessage: {
    fontSize: "12px",
    color: "#f00",
  },
}));

export const AddContactForm: React.FC<IProps> = (props) => {
  const { onContactSubmit } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: props.defaultValues,
    mode: "onChange",
  });

  const classes = useStyles();

  const onSubmit = React.useCallback(
    (contactData: IContact) => {
      onContactSubmit(contactData);
    },
    [onContactSubmit]
  );

  return (
    <Container maxWidth={false} className={classes.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Controller
              name="category_id"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <ContactCategoryDropdown
                  label="Kategoria"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{
                maxLength: 250,
              }}
              render={({ field }) => (
                <FormTextField label="Nazwisko" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: true,
                maxLength: 100,
              }}
              render={({ field }) => (
                <FormTextField label="Imię" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="industry_type"
              control={control}
              render={({ field }) => (
                <FormTextField label="Branża" variant="outlined" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name="tel_mobile"
              control={control}
              render={({ field }) => (
                <FormTextField
                  label="Telefon komórkowy"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <Controller
              name="tel"
              control={control}
              render={({ field }) => (
                <FormTextField label="Telefon" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="tel_alt"
              control={control}
              render={({ field }) => (
                <FormTextField
                  label="Telefon 2"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FormTextField
                    label="email firmowy"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <div className={classes.errorMessage}>
                {errors.email && errors.email.message}
              </div>
            </div>
            <div>
              <Controller
                name="email_alt"
                control={control}
                render={({ field }) => (
                  <FormTextField
                    label="email księgowość"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <div className={classes.errorMessage}>
                {errors.email_alt && errors.email_alt.message}
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            <Controller
              name="street"
              control={control}
              render={({ field }) => (
                <FormTextField label="ulica" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="zip_code"
              control={control}
              render={({ field }) => (
                <FormTextField
                  label="kod pocztowy"
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <FormTextField
                  label="miejscowość"
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name="www"
              control={control}
              render={({ field }) => (
                <FormTextField label="www" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="pesel"
              control={control}
              render={({ field }) => (
                <FormTextField label="Pesel" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="nip"
              control={control}
              render={({ field }) => (
                <FormTextField label="NIP" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="skype"
              control={control}
              render={({ field }) => (
                <FormTextField label="skype" variant="outlined" {...field} />
              )}
            />
            <Controller
              name="bank_account_nb"
              control={control}
              render={({ field }) => (
                <FormTextField label="Rachunek" variant="outlined" {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <FormTextField
                  label="opis"
                  variant="outlined"
                  multiline
                  rows={8}
                  {...field}
                  style={{ width: "100%" }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          sx={{
            paddingTop: "16px",
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // disabled={!getValues("name") || Object.keys(errors).length > 0}
          >
            {props.saveLabel}
          </Button>
        </Grid>
      </form>
    </Container>
  );
};

AddContactForm.defaultProps = {
  defaultValues: {},
  saveLabel: "Dodaj kontakt",
};
