import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAdminResourcePermission } from "./AdminResource";
import { IBillboardEntryData } from "./BasketEntry";
import { ClientDropdown } from "../components/ClientDropdown";
import { AdminResource } from "../components/AdminResource";

interface IProps {
  billboardsData: IBillboardEntryData[];
  selectedClientId: number;
  isBasketValid: boolean;
  onClientSelected: (clientId: number) => void;
  onCreateOfferClick: () => void;
}

const isCreateOfferButtonDisabled = (
  billboardsData: IBillboardEntryData[],
  selectedClientId: number,
  isAdmin: boolean,
  isBasketValid: boolean
) =>
  billboardsData.length === 0 ||
  (isAdmin && selectedClientId === null) ||
  !isBasketValid;

export const BasketHeader: React.FC<IProps> = (props) => {
  const { billboardsData, selectedClientId, onCreateOfferClick } = props;
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getAdminResourcePermission((hasAdminPerm: boolean) => {
      setIsAdmin(hasAdminPerm);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12} container>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" component="h1">
            Koszyk
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", alignItems: "center" }}
        >
          <br />
          <br />
          {billboardsData.length > 0 && (
            <React.Fragment>
              <br />
              <br />
              <AdminResource>
                <div style={{ marginRight: "40px" }}>
                  <ClientDropdown onClientSelected={props.onClientSelected} />
                </div>
              </AdminResource>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isCreateOfferButtonDisabled(
                    billboardsData,
                    selectedClientId,
                    isAdmin,
                    props.isBasketValid
                  )}
                  onClick={onCreateOfferClick}
                >
                  Wyślij ofertę
                </Button>
              </div>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
