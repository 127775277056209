import React from "react";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

import Grid from "@material-ui/core/Grid";

export function Home() {
  return (
    <Container maxWidth="lg">
      <Box mx={4} py={10}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Link
              to="/billboards"
              style={{ textDecoration: "none", textAlign: "center" }}
            >
              <Paper variant="outlined">
                <Box px={4} py={10}>
                  <InsertPhotoIcon
                    sx={{ fontSize: 60 }}
                    style={{ color: "lightgrey" }}
                  />
                  <Typography variant="h4">Lista billboardów</Typography>
                </Box>
              </Paper>
            </Link>
          </Grid>
          <Grid item md={6} xs={12}>
            <Link
              to="/campaigns"
              style={{ textDecoration: "none", textAlign: "center" }}
            >
              <Paper variant="outlined">
                <Box px={4} py={10}>
                  <AssignmentIcon
                    sx={{ fontSize: 60 }}
                    style={{ color: "lightgrey" }}
                  />
                  <Typography variant="h4">Kampanie</Typography>
                </Box>
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
