import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import SimpleReactLightbox from "simple-react-lightbox";
import { createTheme as legacyCreateTheme } from "@material-ui/core/styles";
import { deepOrange, grey } from "@mui/material/colors";
import { ThemeProvider as LegacyThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: deepOrange[500],
    },
    secondary: {
      main: grey["300"],
    },
    text: {
      secondary: grey["700"],
    },
  },
  typography: {
    fontFamily: [
      "IBM Plex Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiFormLabel: {
      // Name of the component ⚛️ / style sheet
      styleOverrides: {
        root: {
          // Name of the rule
          color: "#00a1b299",
          fontSize: "1rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          // Name of the rule
          background: "#fff",
        },
      },
      // Name of the component ⚛️ / style sheet
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "10px 0",
        },
      },
    },
    // MuiDataGrid: {
    //   columnHeaderTitle: {
    //     fontSize: "10px",
    //   },
    //   iconButtonContainer: {
    //     position: "absolute",
    //     right: "2px",
    //   },
    // },
  },
});

const legacyTheme = legacyCreateTheme({
  palette: {
    primary: {
      main: deepOrange[500],
    },
    secondary: {
      main: grey["300"],
    },
    text: {
      secondary: grey["700"],
    },
  },
  typography: {
    fontFamily: [
      "IBM Plex Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiFormLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        color: "#00a1b299",
        fontSize: "1rem",
      },
    },
    MuiOutlinedInput: {
      // Name of the component ⚛️ / style sheet
      input: {
        // Name of the rule
        background: "#fff",
      },
    },
    // MuiTextField: {
    //   root: {
    //     margin: "10px 0",
    //   },
    // },
    MuiDataGrid: {
      columnHeader: {
        fontSize: "10px",
      },
      // iconButtonContainer: {
      //   position: "absolute",
      //   right: "2px",
      // },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <LegacyThemeProvider theme={legacyTheme}>
      <ThemeProvider theme={theme}>
        <SimpleReactLightbox>
          <App />
        </SimpleReactLightbox>
      </ThemeProvider>
    </LegacyThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
