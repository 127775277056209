import React from "react";
import { IBillboard } from "../../interfaces/Billboard";
import makeStyles from "@mui/styles/makeStyles";
import { AdminResource } from "../AdminResource";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Save } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Controller, useForm } from "react-hook-form";
import { FormTextField } from "./Fields/TextField";

interface IProps {
  onSave: (billboard: IBillboard) => void;
  billboard?: IBillboard;
}

const useStyles = makeStyles({
  my: {
    margin: "4px 0",
  },
});

export const AddBillboardItemForm: React.FC<IProps> = ({
  billboard = null,
  onSave,
}) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues:
      billboard ||
      ({
        has_light: false,
        is_near_to_mall: false,
        on_inlet: false,
        is_public: false,
        is_archived: false,
        is_not_standing: false,
        is_to_be_mounted: false,
        is_qm: false,
      } as IBillboard),
    mode: "onChange",
  });

  return (
    <AdminResource>
      <form onSubmit={handleSubmit(onSave)}>
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid item xs={9}>
              <Typography variant="h4">
                {billboard
                  ? `Edycja billboardu #${billboard.search_nr}`
                  : "Dodaj nowy billboard"}
              </Typography>
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Save />}
                disabled={Object.keys(errors).length > 0}
              >
                Zapisz
              </Button>
              <br />
              <br />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={12} md={3}>
                <div>
                  <Controller
                    name="localization_nr"
                    control={control}
                    rules={{
                      required: "Pole wymagane",
                    }}
                    render={({ field }) => (
                      <FormTextField
                        label="Numer*"
                        variant="outlined"
                        type="number"
                        error={!!errors.localization_nr}
                        helperText={errors.localization_nr?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="position_nr"
                    control={control}
                    render={({ field }) => (
                      <FormTextField
                        label="Symbol"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <FormTextField
                        label="Miasto"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="district"
                    control={control}
                    render={({ field }) => (
                      <FormTextField
                        label="Dzielnica"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="address_details"
                    control={control}
                    rules={{
                      required: "Pole wymagane",
                    }}
                    render={({ field }) => (
                      <FormTextField
                        label="Adres*"
                        variant="outlined"
                        error={!!errors.address_details}
                        helperText={errors.address_details?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="rental_price"
                    control={control}
                    rules={{
                      required: "Pole wymagane",
                    }}
                    render={({ field }) => (
                      <FormTextField
                        label="Cena*"
                        variant="outlined"
                        type="number"
                        error={!!errors.rental_price}
                        helperText={errors.rental_price?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="property_owner_id"
                    control={control}
                    render={({ field }) => (
                      <FormTextField
                        label="Właściciel posesji"
                        variant="outlined"
                        {...field}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="width"
                  control={control}
                  rules={{
                    required: "Pole wymagane",
                  }}
                  render={({ field }) => (
                    <FormTextField
                      label="Szerokość*"
                      variant="outlined"
                      type="number"
                      error={!!errors.width}
                      helperText={errors.width?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="height"
                  control={control}
                  rules={{
                    required: "Pole wymagane",
                  }}
                  render={({ field }) => (
                    <FormTextField
                      label="Wysokość*"
                      variant="outlined"
                      type="number"
                      error={!!errors.height}
                      helperText={errors.height?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="road_setup"
                  control={control}
                  render={({ field }) => (
                    <FormTextField
                      label="Ustawienie"
                      variant="outlined"
                      type="number"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="visibility"
                  control={control}
                  render={({ field }) => (
                    <FormTextField
                      label="Widoczność"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="is_near_to_mall"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Blisko centrum handlowe"
                      className={classes.my}
                    />
                  )}
                />
                <Controller
                  name="has_light"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Możliwe oświetlenie"
                      className={classes.my}
                    />
                  )}
                />
                <Controller
                  name="on_inlet"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Wlot"
                      className={classes.my}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="road_number"
                  control={control}
                  render={({ field }) => (
                    <FormTextField
                      label="Trasa"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="road_from"
                  control={control}
                  render={({ field }) => (
                    <FormTextField label="Skąd" variant="outlined" {...field} />
                  )}
                />
                <Controller
                  name="road_to"
                  control={control}
                  render={({ field }) => (
                    <FormTextField
                      label="Dokąd"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="zone_number"
                  control={control}
                  render={({ field }) => (
                    <FormTextField
                      label="Strefa"
                      variant="outlined"
                      type="number"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="region_id"
                  control={control}
                  render={({ field }) => (
                    <FormTextField
                      label="Rejon"
                      variant="outlined"
                      type="number"
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="street_view_coordinates"
                  control={control}
                  rules={{
                    required: "Pole wymagane",
                  }}
                  render={({ field }) => (
                    <FormTextField
                      label="Koordynaty Street View*"
                      variant="outlined"
                      error={!!errors.street_view_coordinates}
                      helperText={errors.street_view_coordinates?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <FormTextField label="Opis" variant="outlined" {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="is_public"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Publiczny"
                      className={classes.my}
                    />
                  )}
                />
                <Controller
                  name="is_not_standing"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Dostawka"
                      className={classes.my}
                    />
                  )}
                />
                <Controller
                  name="is_to_be_mounted"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Do montażu"
                      className={classes.my}
                    />
                  )}
                />
                <Controller
                  name="is_qm"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Do wyjaśnienia"
                      className={classes.my}
                    />
                  )}
                />
                {/* <div className={classes.my}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                />
                <label htmlFor="raised-button-file">
                  <Button component="span">Dodaj zdjęcie</Button>
                </label>
              </div> */}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </form>
    </AdminResource>
  );
};
