import React, { useState } from "react";
import axios from "axios";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import DateFnsAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { TopBar } from "./modules/TopBar";
import { Clients } from "./modules/ClientsList";
import { ClientsAdd } from "./modules/ClientsAdd";
import { Contacts } from "./modules/ContactsList";
import { ContactsItem } from "./modules/ContactsItem";
import { Billboards } from "./modules/Billboards";
import { BillboardsItem } from "./modules/BillboardsItem";
import { Login } from "./modules/Login";
import { AuthenticatedRoute } from "./components/AuthenticatedRouter";
import { AppContextProvider } from "./store/store";
import { Basket } from "./modules/Basket";
import { OfferItem } from "./modules/OfferItem";
import { ContractDocuments } from "./modules/ContractDocumentsList";
import { ContractDocumentsItem } from "./modules/ContractDocumentsItem";
import { ContractDocumentsDashboard } from "./modules/ContractDocumentsDashboard";
import { User } from "./modules/User";
import { Home } from "./modules/Home";
import { Campaigns } from "./modules/CampaignsList";
import {
  BasketSessionStorage,
  getBasketFromSessionStorage,
} from "./components/BasketSessionStorage";
import { ClientsEdit } from "./modules/ClientsEdit";
import { DataContextProvider } from "./dataStore/DataContext";
import { AddBillboardItem } from "./modules/AddBillboardItem";

function App() {
  const [token, setToken] = useState(null);

  React.useEffect(() => {
    axios
      .get("/api/user/verify")
      .then((response) => setToken(response.data.token))
      .catch(() => setToken(""));
  }, []);

  return (
    token !== null && (
      <AppContextProvider
        initialState={{
          token,
          basket: getBasketFromSessionStorage(),
        }}
      >
        <DataContextProvider>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            <BasketSessionStorage>
              <div className="App">
                <Router>
                  <TopBar />
                  <div style={{ marginTop: "100px" }}>
                    <Switch>
                      <Route exact path="/login/" component={Login} />
                      <AuthenticatedRoute
                        exact
                        path="/user/"
                        component={User}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/billboards/"
                        component={Billboards}
                      />
                      <AuthenticatedRoute
                        path="/billboards/:id"
                        component={BillboardsItem}
                      />
                      <AuthenticatedRoute
                        path="/billboardsadd/"
                        component={AddBillboardItem}
                      />
                      <AuthenticatedRoute
                        path="/billboardsedit/:id"
                        component={AddBillboardItem}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/contacts/"
                        component={Contacts}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/clients/"
                        component={Clients}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/clients/add/"
                        component={ClientsAdd}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/clients/edit/:id"
                        component={ClientsEdit}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/basket/"
                        component={Basket}
                      />
                      <AuthenticatedRoute
                        path="/contacts/:id"
                        component={ContactsItem}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/campaigns/"
                        component={Campaigns}
                      />
                      <AuthenticatedRoute
                        path="/offers/:id"
                        component={OfferItem}
                      />
                      <AuthenticatedRoute
                        path="/contracts/:id"
                        component={ContractDocumentsItem}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/contracts/"
                        component={ContractDocuments}
                      />
                      <AuthenticatedRoute
                        exact
                        path="/dashboard/"
                        component={ContractDocumentsDashboard}
                      />
                      <AuthenticatedRoute path="/" component={Home} />
                      <AuthenticatedRoute path="/home/" component={Home} />
                    </Switch>
                  </div>
                </Router>
              </div>
            </BasketSessionStorage>
          </LocalizationProvider>
        </DataContextProvider>
      </AppContextProvider>
    )
  );
}

export default App;
