import React from "react";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { appActions } from "../store/action";
import { AppContext } from "../store/store";
import { BillboardsTable } from "../components/Table/BillboardsTable";
import {
  BillboardDatesSelector,
  ISelectedDates,
} from "../components/BillboardDatesSelector";
import { getEndDate } from "../utils";
import { makeStyles } from "@mui/styles";
import { AdminResource } from "../components/AdminResource";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";

const useStyles = makeStyles({
  datesSelector: {
    margin: "15px 0",
  },
});

export function Billboards() {
  const [selectedBillboardIds, setSelectedBillboardIds] = React.useState<
    number[]
  >([]);
  const [dates, setDates] = React.useState<ISelectedDates>({
    startDate: new Date(),
    endDate: getEndDate(),
  });
  const classes = useStyles();

  const [, dispatch] = React.useContext(AppContext);

  const sendToBasket = React.useCallback(() => {
    dispatch({
      type: appActions.ADD_TO_BASKET,
      payload: {
        shopItems: selectedBillboardIds.map((billboardId) => ({
          startDate: dates.startDate.getTime(),
          endDate: dates.endDate.getTime(),
          billboardId: billboardId,
        })),
      },
    });
  }, [selectedBillboardIds, dates, dispatch]);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={0}>
        <Grid item container wrap="nowrap">
          {/*
          <Button variant="outlined" color="primary">
            Oświetlenie
          </Button>
          <Button variant="outlined" color="primary">
            Wymiary
          </Button>
          <Button variant="outlined" color="primary">
            Oświetlenie
          </Button> */}
          <Box mr={4}>
            <Typography variant="h4">Billboardy</Typography>
          </Box>
          <Grid container item justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={sendToBasket}
              disabled={selectedBillboardIds.length === 0}
            >
              Dodaj do koszyka
            </Button>
            <AdminResource>
              <Box my={1}>
                <Link to={"/billboardsadd/"} aria-label="Dodaj billboard">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                  >
                    Dodaj billboard
                  </Button>
                </Link>
              </Box>
            </AdminResource>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          {/* //TODO: filtrowanie po nazwie
          <Grid item xs={3}>
            <TextField
              className={classes.margin}
              id="input-with-icon-textfield"
              label="Filtruj"
              color="secondary"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid> */}
          <Grid xs={9} container className={classes.datesSelector}>
            <BillboardDatesSelector
              startDate={dates.startDate}
              endDate={dates.endDate}
              onChange={setDates}
            />
          </Grid>
        </Grid>
        <BillboardsTable
          startDate={dates.startDate}
          endDate={dates.endDate}
          onSelectionChanged={setSelectedBillboardIds}
        />
      </Grid>
    </Container>
  );
}
