import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectProps } from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ContactCategories } from "../../constants/ContactCategories";

const useStyles = makeStyles({
  contactCategoryDropdownWrapper: {
    margin: "14px 0 10px",
  },
});

export const ContactCategoryDropdown: React.FC<SelectProps> = (props) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.contactCategoryDropdownWrapper}>
      <InputLabel id="contact-category-select-label">Kategoria</InputLabel>
      <Select labelId="contact-category-select-label" {...props}>
        {Object.keys(ContactCategories).map((categoryId) => (
          <MenuItem value={Number(categoryId)}>
            {ContactCategories[Number(categoryId)]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
