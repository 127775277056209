import React, { useCallback, useState } from "react";
import axios from "axios";
import { RouteComponentProps, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";
import { SRLWrapper } from "simple-react-lightbox";
import { Link } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { formatDate, getEndDate } from "../utils";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { appActions } from "../store/action";
import { AppContext } from "../store/store";
import { IBillboard } from "../interfaces/Billboard";
import { AdminResource } from "../components/AdminResource";
import {
  regions,
  roadSetup,
  zoneNumber,
} from "../constants/BillboardConstants";
import {
  BillboardDatesSelector,
  ISelectedDates,
} from "../components/BillboardDatesSelector";

interface IRouteInfo {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteInfo> {}
//TODO: change api to /billboards and contract in seperate component
const getBillboard = (
  id: number,
  setBillboard: (billboard: IBillboard) => void
) => {
  axios
    .get("/api/billboards/contracts", {
      params: {
        id,
      },
    })
    .then((response) => {
      setBillboard(response.data.billboards[0]);
    })
    .catch((error) => console.log(error));
};

export const BillboardsItem: React.FC<IProps> = () => {
  const [billboard, setBillboard] = useState<IBillboard>(null);
  const [dates, setDates] = useState<ISelectedDates>({
    startDate: new Date(),
    endDate: getEndDate(),
  });
  const [isDateRangeValid, setIsDateRangeValid] = useState(false);

  const [, dispatch] = React.useContext(AppContext);
  const { id } = useParams<IRouteInfo>();

  React.useEffect(() => {
    getBillboard(Number(id), setBillboard);
  }, [id]);

  const useStyles = makeStyles(() => ({
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
  }));

  // TODO: classes.paper????? Remove
  const classes: any = useStyles();

  const onAddShopingCartClick = React.useCallback(() => {
    if (billboard === null) {
      return;
    }

    dispatch({
      type: appActions.ADD_TO_BASKET,
      payload: {
        shopItems: [
          {
            billboardId: billboard.id,
            startDate: dates.startDate.getTime(),
            endDate: dates.endDate.getTime(),
          },
        ],
      },
    });
  }, [billboard, dates.startDate, dates.endDate, dispatch]);

  const onDatesValidation = useCallback(
    (entries: string[]) => {
      setIsDateRangeValid(entries.every((entry) => entry === null));
    },
    [setIsDateRangeValid]
  );

  if (billboard === null) {
    return null;
  }

  // TODO: isMarkerShown??? Remove any
  const MyMapComponent = withScriptjs(
    withGoogleMap((props: any) => (
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{
          lat: parseFloat(props.position[0]),
          lng: parseFloat(props.position[1]),
        }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{
              lat: parseFloat(props.position[0]),
              lng: parseFloat(props.position[1]),
            }}
          />
        )}
      </GoogleMap>
    ))
  );

  const position = billboard.map_coordinates.split(",");
  const photos = JSON.parse(billboard.pictures_serialized);
  const options = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      size: "40px",
    },
  };

  return (
    <>
      <div style={{ background: "#eee" }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <Typography variant="h4">
                <b>#{billboard.search_nr}</b> {billboard.address_details}
              </Typography>
              <Typography variant="subtitle1">
                {billboard.district ? `${billboard.district},` : ""}
                {billboard.city}
              </Typography>
              {billboard.description ? (
                <div>
                  <br />
                  <br />
                  <Typography variant="overline">Opis nośnika</Typography>
                  <div>
                    <Typography variant="subtitle1">
                      {billboard.description}
                    </Typography>
                  </div>
                </div>
              ) : (
                ""
              )}
              <AdminResource>
                <Link
                  to={`/billboardsedit/${billboard.id}`}
                  aria-label="Edytuj billboard"
                >
                  <Button variant="contained" startIcon={<EditIcon />}>
                    Edytuj
                  </Button>
                </Link>
              </AdminResource>
            </Grid>

            <Grid item xs={12} md={3}>
              <div>
                <span>Cena katalogowa</span>
                <div>{billboard.rental_price} PLN + VAT / miesiąc</div>
                <br />
              </div>
              <BillboardDatesSelector
                startDate={dates.startDate}
                endDate={dates.endDate}
                onChange={setDates}
                onValidation={onDatesValidation}
                billboardId={billboard.id}
              />
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddShoppingCartIcon />}
                  onClick={onAddShopingCartClick}
                  disabled={!isDateRangeValid}
                >
                  Dodaj do koszyka
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="lg" style={{ marginTop: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4"></Typography>
            {regions[billboard.region_id] ? (
              <Paper className={classes.paper}>
                <Box p={2}>
                  <div>
                    <Typography variant="overline">Rejon</Typography>
                    <div>{regions[billboard.region_id]}</div>
                  </div>
                </Box>
              </Paper>
            ) : (
              ""
            )}
            <br />
            <Paper className={classes.paper}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="overline">Trasa</Typography>
                    <div>{billboard.road_number}</div>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="overline">Kierunek najazdu</Typography>
                    <div>
                      {billboard.road_from} {" > "}
                      {billboard.road_to}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <br />

            <Paper className={classes.paper}>
              <Box p={2}>
                <div>
                  {billboard.visibility ? (
                    <div>
                      <Typography variant="overline">Widoczność</Typography>
                      <div>{billboard.visibility}</div>
                      <br />
                    </div>
                  ) : (
                    " "
                  )}
                  <div>
                    <Typography variant="overline">Strefa</Typography>
                  </div>
                  <div style={{ display: "flex", padding: "10px 5px" }}>
                    {billboard.on_inlet ? (
                      <span style={{ display: "flex", paddingRight: "4px" }}>
                        <CheckIcon
                          style={{ color: "#40c814", paddingRight: "5px" }}
                        />
                        <Typography variant="body2">
                          Na wlocie {billboard.on_inlet}
                        </Typography>
                      </span>
                    ) : null}

                    <Typography variant="body2">
                      {" "}
                      {zoneNumber[billboard.zone_number]}
                    </Typography>
                  </div>
                  {billboard.is_near_to_mall ? (
                    <div style={{ display: "flex", padding: "10px 5px" }}>
                      <CheckIcon
                        style={{ color: "#40c814", paddingRight: "5px" }}
                      />
                      <Typography variant="body2">
                        Blisko centrum handlowe
                      </Typography>
                    </div>
                  ) : null}
                </div>
              </Box>
            </Paper>

            <br />

            <Paper className={classes.paper}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="overline">Wymiary</Typography>
                    <div>
                      {billboard.width}x{billboard.height}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="overline">Ustawienie</Typography>
                    <div>{roadSetup[billboard.road_setup]}</div>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", padding: "5px" }}>
                      {billboard.has_light ? (
                        <CheckIcon
                          style={{ color: "#40c814", paddingRight: "5px" }}
                        />
                      ) : (
                        <EmojiObjectsIcon
                          style={{ color: "#999999", paddingRight: "5px" }}
                        />
                      )}
                      <Typography variant="body2">
                        {billboard.has_light ? "Możliwe " : "Niemożliwe "}
                        oświetlenie
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <br />
                <br />
                <div>
                  <div>
                    <Typography variant="overline">Inne</Typography>
                    <div>
                      {billboard.is_not_standing ? "Dostawka " : null}
                      {billboard.is_to_be_mounted ? "Do montażu " : null}
                    </div>
                  </div>
                  {/* Współrzędne {billboard.street_view_coordinates} */}
                </div>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <SRLWrapper options={options}>
                <Grid item xs={9}>
                  <br />
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Card>
                        <img
                          src={`/media/${String(photos[0])}`}
                          style={{ width: "100%" }}
                          alt="Billboard"
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {photos.map((photo: number) => (
                      <Grid item xs={3} key={photo}>
                        <Card>
                          <img
                            src={`/media/${String(photo)}`}
                            style={{ width: "100%" }}
                            alt="Billboard"
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </SRLWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MyMapComponent
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDByA1B-AvjSBzc7TBHtxF2AFxchJVrIVM&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              position={position}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {/* TODO: Street View */}
          </Grid>
        </Grid>

        <AdminResource>
          <div>
            <Typography variant="h6" component="h3">
              Daty
            </Typography>
            Utworzono {formatDate(billboard.created_at)}
            <br />
            Zaktualizowano {formatDate(billboard.updated_at)}
            <br />
            <Box p={2} my={14}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" component="h3">
                  Historia kontraktów
                </Typography>
                {billboard.contractDocuments.map((contractDocument) => (
                  <Box
                    my={4}
                    key={
                      contractDocument.contracts_contractedbillboard.contract_id
                    }
                  >
                    <Card>
                      <CardContent>
                        <div>
                          <b>
                            <Link
                              to={`/contracts/${contractDocument.contracts_contractedbillboard.contract_id}`}
                            >
                              {contractDocument.number}
                            </Link>
                          </b>
                        </div>
                        <br />
                        <div>
                          {contractDocument.client_id}
                          <b> {contractDocument.contract_name} </b>
                        </div>
                        <br />
                        <div>
                          {
                            contractDocument.contracts_contractedbillboard
                              .date_from
                          }{" "}
                          -{" "}
                          {
                            contractDocument.contracts_contractedbillboard
                              .date_to
                          }
                        </div>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Grid>
            </Box>
          </div>
        </AdminResource>
      </Container>
    </>
  );
};
