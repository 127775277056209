import React from "react";

import ListItem, { ListItemProps } from "@mui/material/ListItem";
import List from "@mui/material/List";

import { IBillboard } from "../interfaces/Billboard";
import { IContact } from "../interfaces/Contact";
import { Link } from "react-router-dom";
import { IClient } from "../interfaces/Client";

interface IProps {
  results: IQuickSearchResults;
  onClick?: () => void;
}

function ListItemLink(props: ListItemProps<Link, { button?: true }>) {
  return <ListItem button component={Link} {...props} />;
}

const getHeader = (key: resultKey) => {
  switch (key) {
    case "billboards":
      return "Billboardy";
    case "contacts":
      return "Kontakty";
    case "clients":
      return "Kontrahenci";
    default:
      return "";
  }
};

export interface IQuickSearchResults {
  billboards: IBillboard[];
  contacts: IContact[];
  clients: IClient[];
}

type resultKey = "billboards" | "contacts" | "clients";
type resultDataType = Array<IBillboard | IContact>;

const mapResult = (resultData: resultDataType, key: resultKey) =>
  resultData.map((resultItem) => {
    let item;

    switch (key) {
      case "billboards":
        item = resultItem as IBillboard;

        return (
          <ListItemLink
            to={`/billboards/${item.id}`}
            onClick={(e) => e.stopPropagation()}
            key={`${key}_${item.id}`}
          >
            <div>
              <span className="search-nr">
                <b>#{item.search_nr}</b>{" "}
              </span>
              <span className="district">{item.address_details} </span>
              <span className="city">{item.city} </span>
            </div>
          </ListItemLink>
        );
      case "contacts":
        item = resultItem as IContact;
        return (
          <ListItemLink
            to={`/contacts#contact=${item.id}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={`${key}_${item.id}`}
          >
            <div>
              <span className="last-name">{item.last_name} </span>
              <span className="first-name">{item.first_name} </span>
              <span>tel. </span>
              <span className="tel">{item.tel}</span>
            </div>
          </ListItemLink>
        );

      case "clients":
        item = resultItem as IClient;
        return (
          <ListItemLink
            to={`/clients#client=${item.id}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={`${key}_${item.id}`}
          >
            <div>
              <span className="name">{item.name} </span>
              <span className="full-name">{item.full_name} </span>
              <span>tel. </span>
              <span className="tel">{item.tel}</span>
            </div>
          </ListItemLink>
        );
      default:
        return null;
    }
  });

export const QuickSearchResults: React.FC<IProps> = (props) => {
  const results = { ...props.results };

  return (
    <div className="quick-search-results" style={{ width: "500px" }}>
      {Object.keys(results).map((key) => (
        <div key={key} className="result-items" style={{ padding: "10px" }}>
          <div>
            <b style={{ color: "#666" }}>{getHeader(key as resultKey)}</b>
          </div>
          <List component="nav" aria-label="main mailbox folders">
            {mapResult(results[key as resultKey], key as resultKey)}
          </List>
        </div>
      ))}
    </div>
  );
};
