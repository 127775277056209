export enum roadSetup {
  "równoległe" = 1,
  "prostopadłe",
  "ukośne",
};

export enum regions {
  "epicentrum" = 1,
  "centrum",
  "śródmieście",
  "przedmieście",
  "teren wiejski",
  "obwodnica śródmiejska",
  "obwodnica autostradowa AOW/A8",
};

export enum zoneNumber {
  "0 - Centrum" = 0,
  "1 - Bielany Wr. i okolice",
  "2 - Warszawa, Oleśnica, Jelcz L.",
  "3 - Poznań, Oborniki",
  "4 - Zielona Góra",
  "5 - Kąty Wr./A4, Port Lotniczcy",
  "6 - Oława, Strzelin",
  "9 - w Polsce",
};
