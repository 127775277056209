import React from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";

import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { debounce } from "@material-ui/core";
import { IQuickSearchResults, QuickSearchResults } from "./QuickSearchResults";
import { alpha } from "@material-ui/core";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.03),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.07),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(5),
      width: "auto",
      height: "35px",
    },
    color: "black",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "black",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
  },
}));

const getResults = (
  query: string,
  setResults: (results: IQuickSearchResults) => void
) => {
  axios
    .get("/api/search/quicksearch", {
      params: {
        query,
      },
    })
    .then((response) => {
      console.log(response.data);
      setResults(response.data);
    })
    .catch((error) => console.log(error));
};

const onQueryUpdate = debounce((query, setResults) => {
  getResults(query, setResults);
}, 1000);

export const QuickSearch = () => {
  const classes = useStyles();
  const inputRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [results, setResults] = React.useState(null);

  React.useEffect(() => {
    if (
      results !== null &&
      Object.keys(results).some((key) => results[key].length > 0)
    ) {
      setAnchorEl(inputRef.current);
    }
  }, [results]);

  const onEnter = React.useCallback((event) => {
    if (event.key === "Enter") {
      window.location.replace("/billboards/");
    }
  }, []);

  const onInputChange = React.useCallback((event) => {
    onQueryUpdate(event.target.value, setResults);
  }, []);

  const handlePopoverClose = () => {
    // TODO: Nasty hack, find more elegant solution (without this, click on quick search result link closes the popover and redirection is not done)
    setTimeout(() => {
      setAnchorEl(false);
    }, 100);
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        ref={inputRef}
        placeholder="Szukaj..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onKeyDown={onEnter}
        onChange={onInputChange}
        onBlur={handlePopoverClose}
        inputProps={{ "aria-label": "search" }}
      />

      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <Paper>
          <br />
          <br />
          <QuickSearchResults results={results} onClick={handlePopoverClose} />
        </Paper>
      </Popper>
    </div>
  );
};
