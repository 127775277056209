import axios from "axios";
import { IClient } from "../../interfaces/Client";

export const getClients = (query = ""): Promise<IClient[]> =>
  new Promise((resolve) => {
    axios
      .get(`/api/clients${query}`, {})
      .then((response) => {
        resolve(response.data.clients);
      })
      .catch((error) => {
        console.log(error);
        resolve([]);
      });
  });
