import React from "react";
import { createContext, useReducer } from "react";
import { IDataContextAction } from "./DataActions";
import { DataContextState, DataReducer, initialState } from "./DataReducer";

const DataContext =
  createContext<[DataContextState, React.Dispatch<IDataContextAction>]>(null);
const { Provider } = DataContext;

const DataContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(DataReducer, {
    ...initialState,
  });
  return <Provider value={[state, dispatch]}>{children}</Provider>;
};

export { DataContext, DataContextProvider };
