import axios from "axios";

export interface IBillboardOccupancyResponse {
  billboard_id: number;
  date_from: Date;
  date_to: Date;
}

export const getBillboardOccupancy = (
  id: number,
  contractIdsToExclude: number[],
  onSuccess: (data: IBillboardOccupancyResponse[]) => void
) => {
  axios
    .get(`/api/billboards/occupancy`, {
      params: {
        billboard_id: id,
        contract_ids_to_exclude: contractIdsToExclude,
      },
    })
    .then((response) => {
      onSuccess(
        response.data.map((entry) => ({
          ...entry,
          date_from: new Date(entry.date_from),
          date_to: new Date(entry.date_to),
        }))
      );
    })
    .catch((error) => console.log(error));
};
