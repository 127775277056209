import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { IBillboard } from "../interfaces/Billboard";
import { appActions } from "../store/action";
import { AppContext, IShopItem } from "../store/store";
import { BasketEntry, IBillboardEntryData } from "../components/BasketEntry";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { SuccessNotification } from "../components/SuccessNotification";
import { BasketHeader } from "../components/BasketHeader";

const NOTIFICATION_ID = "Basket|Success";

const getBillboards = (ids: number[]): Promise<IBillboard[]> =>
  new Promise((resolve) => {
    if (ids.length > 0) {
      axios
        .get("/api/billboards", {
          params: {
            id: ids,
          },
        })
        .then((response) => {
          resolve(response.data.billboards);
        })
        .catch((error) => console.log(error));
    } else {
      resolve([]);
    }
  });

const addOffer = (shopItems: IShopItem[], clientId: number) =>
  axios.post("api/contracts/add", {
    shopItems,
    clientId,
  });

const isBasketValid = (validationMap: { [id: number]: string[] }) => {
  for (const key in validationMap) {
    if (validationMap[key].some((entry) => entry !== null)) {
      return false;
    }
  }

  return true;
};

export const Basket: React.FC<{}> = () => {
  const [state, dispatch] = useContext(AppContext);

  const [billboardsData, setBillboardsData] = useState<IBillboardEntryData[]>(
    []
  );
  const [selectedClientId, setSelectedClientId] = useState<number>(null);
  const [validationMap, setValidationMap] = useState<{
    [id: number]: string[];
  }>({});

  useEffect(() => {
    const currentCart = [...state.basket];
    getBillboards(
      currentCart.map((shopItem: IShopItem) => shopItem.billboardId)
    ).then((billboards: IBillboard[]) => {
      const data = currentCart.map((shopItem: IShopItem) => {
        const foundBillboard = billboards.find(
          (billboard) => billboard.id === shopItem.billboardId
        );

        return {
          id: foundBillboard.id,
          startDate: shopItem.startDate,
          endDate: shopItem.endDate,
          searchNr: foundBillboard.search_nr,
          addressDetails: foundBillboard.address_details,
          district: foundBillboard.district,
          city: foundBillboard.city,
          rentalPrice: foundBillboard.rental_price,
        };
      });

      setBillboardsData(data);
    });
  }, [state.basket]);

  const validateBasketEntry = useCallback(
    (validation: string[], billboardId: number) => {
      setValidationMap({
        ...validationMap,
        [billboardId]: validation,
      });
    },
    [validationMap, setValidationMap]
  );

  const onRemoveFromBasket = useCallback(
    (billboardId: number) => {
      delete validationMap[billboardId];
      setValidationMap({
        ...validationMap,
      });
    },
    [validationMap, setValidationMap]
  );

  const clearBasket = React.useCallback(() => {
    dispatch({
      type: appActions.CLEAR_BASKET,
    });
  }, [dispatch]);

  const createOffer = React.useCallback(() => {
    const shopItems = state.basket;

    addOffer(shopItems, selectedClientId).then(() => {
      clearBasket();
      dispatch({
        type: appActions.NOTIFICATION_OPEN,
        payload: {
          notificationId: NOTIFICATION_ID,
        },
      });
      setTimeout(() => {
        window.location.replace("/campaigns");
      }, 1000);
    });
  }, [selectedClientId, clearBasket, state.basket, dispatch]);

  return (
    <div>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <BasketHeader
            billboardsData={billboardsData}
            selectedClientId={selectedClientId}
            isBasketValid={isBasketValid(validationMap)}
            onCreateOfferClick={createOffer}
            onClientSelected={setSelectedClientId}
          />
          <Grid item xs={12}>
            {billboardsData.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "300px",
                  flexDirection: "column",
                }}
              >
                <Link to="/billboards">
                  <AddCircleIcon fontSize="large" />
                  <div>Dodaj billboardy</div>
                </Link>
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            {billboardsData.map((entryData, billboardIndex) => (
              <BasketEntry
                billboardEntryData={entryData}
                billboardIndex={billboardIndex}
                onRemoveFromBasket={onRemoveFromBasket}
                onValidation={validateBasketEntry}
                key={`${entryData.id}_${entryData.startDate}_${entryData.endDate}`}
              />
            ))}
            <Box my={6}>
              {billboardsData.length === 0 ? (
                ""
              ) : (
                <Button
                  onClick={clearBasket}
                    disabled={billboardsData.length === 0}
                    variant="contained"
                >
                  Wyczyść koszyk
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <SuccessNotification
        id={NOTIFICATION_ID}
        label="Zapytanie zostało wysłane."
      />
    </div>
  );
};
