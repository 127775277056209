import { useEffect, useState } from "react";
import {
  getBillboardOccupancy,
  IBillboardOccupancyResponse,
} from "../services/rest.services";

export const useBillboardOccupancy = (
  billboardId: number,
  contractIdsToExclude?: number[]
): [IBillboardOccupancyResponse[], boolean] => {
  const [isLoading, setIsLoading] = useState(false);
  const [billboardOccupancy, setBillboardOccupancy] = useState<
    IBillboardOccupancyResponse[]
  >([]);

  useEffect(() => {
    if (billboardId != null) {
      setIsLoading(true);
      getBillboardOccupancy(billboardId, contractIdsToExclude, (data) => {
        setBillboardOccupancy(data);
        setIsLoading(false);
      });
    }
  }, [billboardId, contractIdsToExclude]);

  return [billboardOccupancy, isLoading];
};
