import React from "react";
import axios from "axios";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Add from "@material-ui/icons/Add";
import { IContractDocument } from "../interfaces/ContractDocument";
import { ContractDocumentCard } from "../components/ContractDocumentCard";
import { AdminResource } from "../components/AdminResource";

const getContractDocuments = (
  setContractDocuments: (contractDocuments: IContractDocument[]) => void,
  params: { [key: string]: string | number } = null
) => {
  axios
    .get("/api/contracts", {
      params: {
        size: 10000,
        ...params,
      },
    })
    .then((response) => {
      setContractDocuments(response.data.contractDocuments);
    })
    .catch((error) => console.log(error));
};

export function ContractDocumentsDashboard() {
  const [contractDocuments, setContractDocuments] = React.useState([]);

  React.useEffect(() => {
    getContractDocuments(setContractDocuments);
  }, []);

  const filters = React.useCallback(
    (params: { [key: string]: string | number }) => {
      getContractDocuments(setContractDocuments, params);
    },
    [setContractDocuments]
  );

  //TODO: Check disableClickEventBubbling props
  return (
    <AdminResource>
      <Container maxWidth="lg">
        <Grid container spacing={0}>
          <Grid item xs={9}>
            <Typography variant="h4">Historia zamówień</Typography>
            <ButtonGroup
              color="secondary"
              aria-label="outlined secondary button group"
            >
              <Button onClick={() => filters(null)}>Wszyscy</Button>
              <Button onClick={() => filters({ category_id: 2 })}>
                Znajomi
              </Button>
              <Button onClick={() => filters({ category_id: 1 })}>
                Klienci
              </Button>
            </ButtonGroup>

            <Button variant="contained" color="secondary" startIcon={<Add />}>
              Dodaj nowy kontrakt
            </Button>
          </Grid>
          <Grid item xs={9}>
            {contractDocuments.map((contractDocument) => (
              <ContractDocumentCard
                contractDocument={contractDocument}
                key={contractDocument.id}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </AdminResource>
  );
}
