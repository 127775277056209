import React, { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { AddBillboardItemForm } from "../components/Forms/AddBillboardItemForm";
import { SuccessNotification } from "../components/SuccessNotification";
import { IBillboard } from "../interfaces/Billboard";
import {
  addBillboard,
  editBillboard,
  getBillboard,
} from "../services/rest/billboards";
import { appActions } from "../store/action";
import { AppContext } from "../store/store";

interface IRouteInfo {
  id: string;
}

const ADD_BILLBOARD_NOTIFICATION_ID = "AddBillboard|Success";
const EDIT_BILLBOARD_NOTIFICATION_ID = "EditBillboard|Success";

export const AddBillboardItem: React.FC<
  RouteComponentProps<IRouteInfo>
> = () => {
  const [billboard, setBillboard] = useState<IBillboard>(null);
  const { id } = useParams<IRouteInfo>();
  const [, dispatch] = useContext(AppContext);

  useEffect(() => {
    getBillboard(id).then((billboard) => {
      setBillboard(billboard);
    });
  }, [id]);

  const onSave = useCallback(
    (billboard: IBillboard) => {
      if (id) {
        editBillboard(billboard).then((id: string) => {
          dispatch({
            type: appActions.NOTIFICATION_OPEN,
            payload: {
              notificationId: EDIT_BILLBOARD_NOTIFICATION_ID,
            },
          });
          setTimeout(() => {
            window.location.replace(`/billboards/${id}`);
          }, 1000);
        });
      } else {
        addBillboard(billboard).then((id: string) => {
          dispatch({
            type: appActions.NOTIFICATION_OPEN,
            payload: {
              notificationId: ADD_BILLBOARD_NOTIFICATION_ID,
            },
          });
          setTimeout(() => {
            window.location.replace(`/billboards/${id}`);
          }, 1000);
        });
      }
    },
    [id, dispatch]
  );

  return (
    <>
      {id ? (
        billboard === null ? null : (
          <AddBillboardItemForm billboard={billboard} onSave={onSave} />
        )
      ) : (
        <AddBillboardItemForm onSave={onSave} />
      )}
      <SuccessNotification
        id={ADD_BILLBOARD_NOTIFICATION_ID}
        label="Dodawanie billboardu zakończone pomyślnie. Trwa przekierowywanie na stronę billboardu."
      />
      <SuccessNotification
        id={EDIT_BILLBOARD_NOTIFICATION_ID}
        label="Edycja billboardu zakończona pomyślnie. Trwa przekierowywanie na stronę billboardu."
      />
    </>
  );
};
