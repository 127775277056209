import React from "react";
import { Link } from "react-router-dom";

import { GridCellParams } from "@mui/x-data-grid";

import { formatDate } from "../../../utils";
import { IClient } from "../../../interfaces/Client";

export const OfferDocumentsColumns = [
  {
    field: "id",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Nazwa",
    width: 330,
    renderCell: (params: GridCellParams) => (
      <Link to={`/offers/${params.getValue(params.id, "id")}`}>
        <b>{params.row.contract_name}</b>
      </Link>
    ),
  },
  {
    field: "client",
    headerName: "Klient",
    width: 230,
    renderCell: (params: GridCellParams) => (
      <span>{(params.getValue(params.id, "client") as IClient).name}</span>
    ),
  },
  { field: "status", headerName: "Status", width: 130 },
  {
    field: "created_at",
    headerName: "Data",
    width: 230,
    renderCell: (params: GridCellParams) => (
      <span>
        {formatDate(String(params.getValue(params.id, "created_at")))}
      </span>
    ),
  },
];
