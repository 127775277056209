// store.js
import React, { createContext, useReducer } from "react";
import { appActions } from "./action";
import { appReducer } from "./appReducer";

interface IProps {
  initialState?: AppContextState;
}

export interface IShopItem {
  billboardId?: number;
  startDate?: number;
  endDate?: number;
}

interface IPayloadShopItem extends IShopItem {
  billboardIndex?: number;
}

export type AppContextState = {
  token?: string;
  basket?: IShopItem[];
  notifications?: {
    [id: string]: boolean;
  };
  modals?: {
    [id: string]: boolean;
  };
};

export interface IAppContextAction {
  type: appActions;
  payload?: {
    shopItems?: IPayloadShopItem[];
    token?: string;
    notificationId?: string;
    modalId?: string;
  };
}

const defaultInitialState: AppContextState = {
  basket: [],
  notifications: {},
  modals: {},
};

const AppContext =
  createContext<[AppContextState, React.Dispatch<IAppContextAction>]>(null);
const { Provider } = AppContext;

const AppContextProvider: React.FC<IProps> = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(appReducer, {
    ...defaultInitialState,
    ...initialState,
  });
  return <Provider value={[state, dispatch]}>{children}</Provider>;
};

export { AppContext, AppContextProvider };
