import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";

import { IconButton } from "@material-ui/core";

interface IProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmationContentText?: string;
}

export const DeleteButton: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { onConfirm, onCancel } = props;

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onCorfirmAction = React.useCallback(() => {
    onClose();
    onConfirm();
  }, [onClose, onConfirm]);

  const onCancelAction = React.useCallback(() => {
    onClose();
    onCancel();
  }, [onClose, onCancel]);

  return (
    <div>
      <IconButton size="small" aria-label="delete" onClick={onOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Usuwanie billboardu</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="textPrimary">
            {props.confirmationContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelAction}>Anuluj</Button>
          <Button
            onClick={onCorfirmAction}
            color="primary"
            variant="contained"
            autoFocus
          >
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteButton.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  confirmationContentText: "",
};
