import { Box, makeStyles } from "@material-ui/core";
import React from "react";

interface IProps {
  search_nr: number;
  city: string;
  district: string;
  address_details: string;
  description: string;
}

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: "2px",
    fontSize: "14px",
  },
  description: {
    fontSize: "12px",
    marginBottom: "8px",
  },
}));

export const BillboardOptionLabel: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Box>
      <div className={classes.header}>
        <span>{props.search_nr}</span>
        <span> {props.city}</span>
        <span> {props.district}</span>
      </div>
      <div className={classes.description}>
        <div>{props.address_details}</div>
        <div>{props.description}</div>
      </div>
    </Box>
  );
};
