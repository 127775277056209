import React, { SyntheticEvent } from "react";

import { Snackbar, SnackbarCloseReason } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { AppContext } from "../store/store";
import { appActions } from "../store/action";

interface IProps {
  id: string;
  label: string;
}

export const SuccessNotification: React.FC<IProps> = (props) => {
  const [state, dispatch] = React.useContext(AppContext);

  const handleSnackbarClose = (
    event: SyntheticEvent<Element, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({
      type: appActions.NOTIFICATION_CLOSE,
      payload: {
        notificationId: props.id,
      },
    });
  };

  return (
    <Snackbar
      open={state.notifications[props.id]}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleSnackbarClose}
        severity="success"
      >
        {props.label}
      </MuiAlert>
    </Snackbar>
  );
};
