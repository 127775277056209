import React from "react";

import IconButton from "@material-ui/core/IconButton";
import { appActions } from "../store/action";
import { AppContext } from "../store/store";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import {
  BillboardDatesSelector,
  ISelectedDates,
} from "./BillboardDatesSelector";
import { makeStyles } from "@mui/styles";

interface IProps {
  billboardEntryData: IBillboardEntryData;
  billboardIndex: number;
  onRemoveFromBasket: (billboardId: number) => void;
  onValidation?: (entries: string[], billboardId?: number) => void;
}

export interface IBillboardEntryData {
  id: number;
  startDate: number;
  endDate: number;
  searchNr: number;
  addressDetails: string;
  district: string;
  city: string;
  rentalPrice: string;
}

const useStyles = makeStyles({
  datesSelector: {
    marginTop: "15px",
  },
});

export const BasketEntry: React.FC<IProps> = (props) => {
  const [, dispatch] = React.useContext(AppContext);
  const [billboardEntry, updateBillboardEntry] = React.useState(
    props.billboardEntryData
  );
  const classes = useStyles();

  const { billboardIndex, onRemoveFromBasket } = props;
  const {
    id,
    searchNr,
    addressDetails,
    district,
    city,
    rentalPrice,
    startDate,
    endDate,
  } = billboardEntry;

  const removeFromBasket = React.useCallback(() => {
    dispatch({
      type: appActions.REMOVE_FROM_BASKET,
      payload: {
        shopItems: [
          {
            billboardIndex,
          },
        ],
      },
    });
    onRemoveFromBasket(id);
  }, [billboardIndex, id, onRemoveFromBasket, dispatch]);

  const onDatesChange = React.useCallback(
    (date: ISelectedDates) => {
      const newStartDate = date.startDate.getTime();
      const newEndDate = date.endDate.getTime();

      dispatch({
        type: appActions.UPDATE_BASKET,
        payload: {
          shopItems: [
            {
              billboardId: id,
              billboardIndex,
              startDate: newStartDate,
              endDate: newEndDate,
            },
          ],
        },
      });

      updateBillboardEntry({
        ...billboardEntry,
        startDate: newStartDate,
        endDate: newEndDate,
      });
    },
    [billboardIndex, id, billboardEntry, dispatch]
  );

  return (
    <Box component="div" my={2}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="h6" component="h3">
                {/* <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ "aria-label": "zaznacz" }}
                /> */}
                <Link to={`/billboards/${id}`}>
                  #{searchNr} {addressDetails}
                </Link>
              </Typography>
              <div>
                {district}, {city}
              </div>
              <div>Cena katalogowa {rentalPrice} PLN + VAT / miesiąc</div>
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignContent="flex-start"
            >
              <IconButton onClick={removeFromBasket}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid xs={12} container className={classes.datesSelector}>
            <BillboardDatesSelector
              startDate={new Date(startDate)}
              endDate={new Date(endDate)}
              onChange={onDatesChange}
              onValidation={props.onValidation}
              billboardId={id}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
