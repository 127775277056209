import React from "react";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import { AdminResource } from "../components/AdminResource";
import { AddClientForm } from "../components/Forms/AddClientForm";
import { IClient } from "../interfaces/Client";
import axios from "axios";
import { SuccessNotification } from "../components/SuccessNotification";
import { AppContext } from "../store/store";
import { appActions } from "../store/action";

const NOTIFICATION_ID = "Basket|Success";

const addClient = (clientData: IClient) =>
  axios.post("/api/clients/add", {
    data: clientData,
  });

export function ClientsAdd() {
  const [, dispatch] = React.useContext(AppContext);

  const onClientCreated = React.useCallback((clientData: IClient) => {
    addClient(clientData).then(() => {
      dispatch({
        type: appActions.NOTIFICATION_OPEN,
        payload: {
          notificationId: NOTIFICATION_ID,
        },
      });
    });
  }, [dispatch]);

  return (
    <AdminResource>
      <React.Fragment>
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid item xs={12} container>
              <AddClientForm onClientSubmit={onClientCreated} />
            </Grid>
          </Grid>
        </Container>
        <SuccessNotification
          id={NOTIFICATION_ID}
          label="Kontrahent został dodany."
        />
      </React.Fragment>
    </AdminResource>
  );
}
