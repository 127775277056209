import React from "react";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
// import Add from "@material-ui/icons/Add";
import { ContractDocumentsTable } from "../components/Table/ContractDocumentsTable";

export function ContractDocuments() {
  //TODO: Check disableClickEventBubbling props
  return (
    <Container maxWidth="lg">
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Typography variant="h4">Historia kontraktów</Typography>
          {/* 
          <Button variant="contained" color="secondary" startIcon={<Add />}>
            Dodaj nowy kontrakt
          </Button> */}
        </Grid>
        <div style={{ height: "calc(100vh - 210px)", width: "100%" }}>
          <ContractDocumentsTable />
        </div>
      </Grid>
    </Container>
  );
}
