import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AppContext } from "../store/store";

export const AuthenticatedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const [state] = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        state.token ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: routeProps.location } }}
          />
        )
      }
    />
  );
};
