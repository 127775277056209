export const ContactCategories: { [key: number]: string } = {
  1: "Klienci",
  2: "Znajomi",
  3: "Partnerzy",
  4: "Agencje",
  5: "Posesje",
  6: "Archiwum",
  7: "Auto",
  9: "Faktury",
};
