import React, { useContext } from "react";

import { IBillboard } from "../../interfaces/Billboard";
import { BillboardColumns } from "./ColumnDefinitions/BillboardColumns";
import { Table } from "./Table";
import { GridSelectionModel } from "@material-ui/data-grid";
import { DataContext } from "../../dataStore/DataContext";
import { getBillboards } from "../../services/rest/billboards";
import { DataActions } from "../../dataStore/DataActions";

interface IProps {
  startDate: Date;
  endDate: Date;
  onSelectionChanged: (billboardIds: number[]) => void;
}

const columns = [
  BillboardColumns.id,
  BillboardColumns.searchNr,
  BillboardColumns.addressDetails,
  BillboardColumns.size,
  BillboardColumns.status,
  BillboardColumns.city,
  BillboardColumns.roadNumber,
  BillboardColumns.roadFrom,
  BillboardColumns.hasLight,
  BillboardColumns.onInlet,
  BillboardColumns.isNearToMall,
  BillboardColumns.zoneNumber,
  BillboardColumns.regionId,
  BillboardColumns.roadSetup,
];

export const BillboardsTable: React.FC<IProps> = (props) => {
  const [billboards, setBillboards] = React.useState<IBillboard[]>([]);
  const { startDate, endDate, onSelectionChanged } = props;
  const [state, dispatch] = useContext(DataContext);

  React.useEffect(() => {
    dispatch({
      type: DataActions.ENABLE_LOADING,
    });
    getBillboards(startDate, endDate).then((billboards) => {
      setBillboards(billboards);
      dispatch({
        type: DataActions.DISABLE_LOADING,
      });
    });
  }, [startDate, endDate, dispatch]);

  const changeSelection = React.useCallback(
    (newSelection: GridSelectionModel) => {
      onSelectionChanged(newSelection as number[]);
    },
    [onSelectionChanged]
  );

  return (
    <Table
      rows={billboards}
      columns={columns}
      onSelectionChanged={changeSelection}
      loading={state.isLoading}
    />
  );
};
