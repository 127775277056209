import React, { ReactElement } from "react";

import Button from "@material-ui/core/Button";
import { Menu as MaterialUIMenu } from "@material-ui/core";

interface IProps {
  id: string;
  label: string | ReactElement;
}

export const Menu: React.FC<IProps> = (props) => {
  const [triggerEl, setTriggerEl] = React.useState(null);

  const handleOpen = React.useCallback((event) => {
    setTriggerEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setTriggerEl(null);
  }, []);

  return (
    <>
      <Button
        color="inherit"
        aria-controls={props.id}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        {props.label}
      </Button>
      <MaterialUIMenu
        id={props.id}
        anchorEl={triggerEl}
        keepMounted
        open={!!triggerEl}
        onClose={handleClose}
      >
        {React.Children.map(props.children, (child: any) => (
          React.cloneElement(child, {onClick: () => {
            handleClose();
            child.props.onClick();
          }})
        ))}
      </MaterialUIMenu>
    </>
  )
}