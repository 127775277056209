import React from "react";
import axios from "axios";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IClient } from "../interfaces/Client";
import { debounce } from "../utils";

export interface IProps {
  onClientSelected: (clientId: number) => void;
}

const getClients = debounce(
  (setClients: (clients: IClient[]) => void, query: string) => {
    axios
      .get("/api/clients", {
        params: {
          name: query,
          size: 50,
          fields: ["id", "name"],
        },
      })
      .then((response) => {
        setClients(response.data.clients);
      })
      .catch((error) => console.log(error));
  },
  300
);

export const ClientDropdown: React.FC<IProps> = (props) => {
  const [clients, setClients] = React.useState<IClient[]>([]);
  const { onClientSelected } = props;

  const onInputChange = React.useCallback((event) => {
    getClients(setClients, event.currentTarget.value);
  }, []);

  const onClientChange = React.useCallback(
    (event, value: string | IClient) => {
      onClientSelected(value ? (value as IClient).id : null);
    },
    [onClientSelected]
  );

  React.useEffect(() => {
    getClients(setClients, "");
  }, []);

  return (
      <Autocomplete
        id="combo-box-demo"
        options={clients}
        getOptionLabel={(option) => option.name}
        style={{ width: 300 }}
        onChange={onClientChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Wybierz klienta"
            variant="outlined"
            onChange={onInputChange}
          />
        )}
      />
  );
};
