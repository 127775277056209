import React from "react";

import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { IClient } from "../interfaces/Client";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { formatDate } from "../utils";
import Paper from "@material-ui/core/Paper";

interface IProps {
  onDrawerClose?: () => void;
  drawerState: boolean;
  item: IClient;
}

export const PreviewClient: React.FC<IProps> = (props) => {
  return (
    <>
      <Drawer
        anchor="right"
        open={props.drawerState}
        onClose={props.onDrawerClose}
        variant="persistent"
      >
        <Box m={4} width={600}>
          <Grid item container justifyContent="space-between">
            <Box my={1}>
              <Link
                to={`/clients/edit/${props.item.id}`}
                aria-label="Edytuj klienta"
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Edit />}
                >
                  Edytuj kontrahenta
                </Button>
              </Link>
            </Box>
            <IconButton color="primary" onClick={props.onDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Typography variant="subtitle2">Kontrahent </Typography>
          <Typography variant="h4">{props.item.name}</Typography>
          <Box my={2}>
            <Typography variant="body1">{props.item.industry_type}</Typography>
          </Box>
          <Paper>
            <Box my={2} p={1}>
              <Typography variant="overline">Siedziba</Typography>
              <Typography variant="body1">
                <br />
                <b>{props.item.full_name}</b>
                <br />
                {props.item.zip_code} {props.item.city}, {props.item.street}
              </Typography>
              {props.item.nip ? (
                <Box my={2}>
                  <Typography variant="body1">NIP {props.item.nip}</Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Paper>
          <Paper>
            <Box my={2} p={1}>
              <br />
              {props.item.mail_street || props.item.mail_city ? (
                <div>
                  <Typography variant="overline">
                    Adres korespondecyjny
                  </Typography>
                  <Typography variant="body1">
                    <br />
                    {props.item.mail_street}
                    <br /> {props.item.mail_zip_code} {props.item.mail_city}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Box>
          </Paper>
          <Paper>
            <Box my={2} p={1}>
              <Typography variant="overline">Dane kontaktowe</Typography>
              <Box my={2}>
                <Typography variant="body1">
                  <b>tel.</b> {props.item.tel}
                </Typography>
              </Box>
              <Box my={2}>
                <Typography variant="body1">
                  <b>tel. </b>
                  {props.item.tel_alt}
                </Typography>
              </Box>
              <Box my={2}>
                <Typography variant="body1">
                  <b>GSM</b> {props.item.tel_mobile}
                </Typography>
              </Box>
              {props.item.email ? (
                <Box my={2}>
                  <Typography variant="body1">
                    <b>e-mail firmowy</b> {props.item.email}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              {props.item.email_alt ? (
                <Box my={2}>
                  <Typography variant="body1">
                    <b>e-mail księgowość</b> {props.item.email_alt}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Paper>
          <Paper>
            <Box my={2} p={1}>
              {props.item.krs ? (
                <Box my={2}>
                  <Typography variant="body1">KRS {props.item.krs}</Typography>
                </Box>
              ) : (
                ""
              )}
              {props.item.regon ? (
                <Box my={2}>
                  <Typography variant="body1">
                    REGON {props.item.regon}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              <Box my={2}>
                <Typography variant="body1">{props.item.www}</Typography>
              </Box>
              {props.item.bank_account_nb ? (
                <Box my={2}>
                  <Typography variant="body1">
                    <b>Bank</b> {props.item.bank_account_nb}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              {props.item.skype ? (
                <Box my={2}>
                  <Typography variant="body1">
                    Skype {props.item.skype}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Paper>
          <Paper>
            <Box my={2} p={1}>
              <Typography variant="overline">Opis</Typography>
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {props.item.description}
              </Typography>
            </Box>
          </Paper>
          Utworzono {formatDate(props.item.created_at)}
          <br />
        </Box>
      </Drawer>
    </>
  );
};
