import React from "react";

import { IContractDocument } from "../interfaces/ContractDocument";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { IBillboard } from "../interfaces/Billboard";

interface IProps {
  contractDocument: IContractDocument;
}

const getStartEndDate = (array: IBillboard[]) => {
  const start = array.map(
    (item) => item.contracts_contractedbillboard.date_from
  );

  if (start.length > 0) {
    const from = start.reduce(function (pre, cur) {
      return Date.parse(pre) > Date.parse(cur) ? cur : pre;
    });

    const end = array.map((item) => item.contracts_contractedbillboard.date_to);
    const to = end.reduce(function (pre, cur) {
      return Date.parse(pre) < Date.parse(cur) ? cur : pre;
    });
    return `${from} - ${to}`;
  }

  console.warn("Provided client hasn't billboard attached");
  return "Brak billboardów";
};

const getStatus = (array: IBillboard[]) => {
  const end = array.map((item) => item.contracts_contractedbillboard.date_to);
  let to;

  if (end.length > 0) {
    to = end.reduce(function (pre, cur) {
      return Date.parse(pre) < Date.parse(cur) ? cur : pre;
    });
  } else {
    to = "";
    console.warn("Provided client hasn't billboard attached");
  }

  const now = new Date();

  if (Date.parse(to) < Date.parse(String(now))) {
    //Capaign has ended
    return "Zakończona";
  } else {
    return "Aktualna";
  }
};

export const ContractDocumentCard: React.FC<IProps> = (props) => {
  console.log(props);
  return (
    <Box component="div" my={2}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="h6" component="h3">
                <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ "aria-label": "zaznacz" }}
                />
                <Link to={`/contracts/${props.contractDocument.id}`}>
                  {props.contractDocument.number}
                </Link>
              </Typography>
              <div>{props.contractDocument.contract_name}</div>
              <div>{props.contractDocument.client.name}</div>
              {/* <div>{props.contractDocument.created_at}</div> */}
              <div>Status: {getStatus(props.contractDocument.billboards)}</div>

              <b>{getStartEndDate(props.contractDocument.billboards)}</b>
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignContent="flex-start"
            >
              Billboardy: {props.contractDocument.billboards.length}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
