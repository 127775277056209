import { useContext, useEffect } from "react";
import { AppContext } from "../store/store";

interface IProps {
  children: React.ReactElement;
}

export const getBasketFromSessionStorage = () => {
  const basket = localStorage.getItem("basket");
  return basket ? JSON.parse(basket) : [];
};

export const BasketSessionStorage: React.FC<IProps> = (props) => {
  const [{ basket }] = useContext(AppContext);

  useEffect(() => {
    localStorage.setItem("basket", JSON.stringify(basket));
  }, [basket]);

  return props.children;
};
